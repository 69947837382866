import React from 'react';
import { StyledBranchDetailBtn } from '../styles';
import { IcoArrowBlack } from 'src/components/Icon';

type TProps = React.ButtonHTMLAttributes<HTMLButtonElement>;

function BranchDetailBtn({ ...props }: TProps): React.ReactElement {
  return (
    <StyledBranchDetailBtn {...props}>
      상세보기
      <p>
        <IcoArrowBlack />
      </p>
    </StyledBranchDetailBtn>
  );
}

export default BranchDetailBtn;
