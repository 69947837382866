import React from 'react';
import { StyledButton, StyledButtonGroup } from './styles';
import { theme } from 'src/styles/theme';

const objColor = {
  red: theme.colors.red,
  black: theme.colors.black,
  blue: theme.colors.blue,
  gray: theme.colors.gray01,
  gray_light: '#ececec',
  green: theme.colors.green,
  red_light: theme.colors.red02,
};

/**
 * @name 기본 버튼
 * @param text 버튼 이름
 * @param variant 버튼 스타일
 * @param buttonColor 버튼 색상
 */
type ButtonProps = {
  onClick?: () => void;
  text: string | React.ReactNode;
  variant?: 'text' | 'contained' | 'outlined';
  buttonColor?:
    | 'red'
    | 'black'
    | 'blue'
    | 'gray'
    | 'gray_light'
    | 'green'
    | 'red_light';
};

export const Button = (props: ButtonProps): React.ReactElement => {
  return (
    <StyledButton
      title={String(props.text)}
      onClick={props.onClick && props.onClick}
      variant={props.variant || 'contained'}
      buttonColor={objColor[props.buttonColor || 'red']}
    >
      {props.text}
    </StyledButton>
  );
};

/**
 * @name 기본 버튼 Wrapper (2개 이상일 경우 사용!!)
 * @prop count = 버튼갯수
 */
type ButtonGroupProps = {
  count: number;
  children: React.ReactNode;
};

export const ButtonGroup = (props: ButtonGroupProps) => {
  return (
    <StyledButtonGroup count={props.count}>{props.children}</StyledButtonGroup>
  );
};
