import React from 'react';
import { StyledBranchLabel } from '../styles';
import { TBranch } from 'src/types/types';

type TProps = {
  branch: TBranch;
};

function BranchLabel(props: TProps): React.ReactElement {
  return (
    <StyledBranchLabel>
      {props.branch.main_yn === 'Y' && (
        <span className="choice">주이용점포</span>
      )}
      {props.branch.scango_yn === 'Y' && <span>SCAN&GO</span>}
    </StyledBranchLabel>
  );
}

export default BranchLabel;
