import styled, { css } from 'styled-components';
import { TImageStyleProps } from '.';
import { rem } from 'src/styles/function';

/**
 * @name 이미지 스타일 컴포넌트
 * @params imageStyles 이미지 스타일
 */
export const StyledImage = styled.img<TImageStyleProps>`
  display: block;
  width: 100%;
  ${({ borderRadius = rem(10.4) }) => css`
    border-radius: ${borderRadius};
  `}
`;
