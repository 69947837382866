import React from 'react';
import { StyledSelectRegion } from '../styles';
import { IcoSearch } from 'src/components/Icon';
import {
  SelectBoxBorderDropDown,
  TSelectBoxBorderDropDown,
} from 'src/components/SelectBox';
import { TBranchTerms, TRegion } from 'src/types/types';
import { gaEvent } from 'src/utils/ga';

type TProps = {
  regionList: TRegion[];
  selectTerms: TBranchTerms;
  onChangeRegion: (region) => void;
  onChangeBranchName: (e) => void;
  onClickSearch: (storeType, regionCd, strNm) => void;
};

function SelectRegion(props: TProps): React.ReactElement {
  // 지역 선택
  const handleChangeOption = (option: TSelectBoxBorderDropDown) => {
    gaEvent('지역 선택', `${option.cd}`, `${option.name}`, '주이용점포 팝업');
    props.onChangeRegion(option.cd);
    handleClickSearch(option.cd);
  };

  // 검색하기 아이콘 클릭
  const handleClickSearch = regionCd => {
    gaEvent('검색하기 아이콘', '', '', '주이용점포 팝업');
    props.onClickSearch(
      props.selectTerms.main_str_type,
      regionCd,
      props.selectTerms.str_nm,
    );
  };

  // 엔터 입력 시
  const handleKeyPress = e => {
    const key = e.which || e.keyCode || e.charCode;
    if (key === 13) {
      handleClickSearch(props.selectTerms.region_cd);
    }
  };

  return (
    <StyledSelectRegion>
      <h3 className="sub_title">지역 검색</h3>
      <div>
        <SelectBoxBorderDropDown
          filterList={props.regionList}
          onChange={handleChangeOption}
          defaultOption={{ cd: '', name: '전체' }}
        />
      </div>
      <div className="branch_search">
        <input
          placeholder="점포명을 입력해주세요"
          onChange={e => props.onChangeBranchName(e)}
          onKeyPress={e => handleKeyPress(e)}
        />
        <p
          className="ico_search"
          onClick={() => handleClickSearch(props.selectTerms.region_cd)}
        >
          <IcoSearch />
        </p>
      </div>
    </StyledSelectRegion>
  );
}

export default SelectRegion;
