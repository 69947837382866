import React from 'react';
import { StyledLodingPopup } from './styles';

function LoadingPopup(): React.ReactElement {
  return (
    <StyledLodingPopup>
      <div className="loading">
        <div className="loading_ico"></div>
      </div>
    </StyledLodingPopup>
  );
}

export default LoadingPopup;
