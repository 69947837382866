import React, { useState, useEffect } from 'react';

import { fillZero } from 'src/utils/utility';

import { IcoClock } from 'src/components/Icon';

interface Props {
  timeLeft: number;
  getIsExpired: (boolean) => void;
}

function SnowPointTimer(props: Props): React.ReactElement {
  const [time, setTime] = useState({ min: '3', sec: '00' });
  const [seconds, setSeconds] = useState(props.timeLeft);

  const timer = seconds => {
    if (seconds <= 0) {
      return { min: '0', sec: '00' };
    }

    const min = Math.floor(seconds / 60).toString();
    const sec = fillZero(2, (seconds % 60).toString());
    return { min, sec };
  };

  useEffect(() => {
    let intervalId;

    if (seconds > 0) {
      intervalId = setInterval(() => {
        setSeconds(prevSeconds => prevSeconds - 1);
      }, 1000);
    }

    return () => {
      clearInterval(intervalId);
    };
  }, [seconds]);

  useEffect(() => {
    setTime(timer(seconds));
  }, [seconds]);

  useEffect(() => {
    if (time.min === '0' && time.sec === '00') {
      props.getIsExpired(true);
    }
  }, [props, time]);

  return (
    <div className="time">
      <i>
        <IcoClock />
      </i>
      <div className="dec">
        남은 시간
        <p className="hightlight">
          {time.min}:{time.sec}
        </p>
        초
      </div>
    </div>
  );
}

export default SnowPointTimer;
