import { getUA } from 'react-device-detect';

export const IMAGE_ROOT = `${process.env.REACT_APP_BASE_S3_IMAGE}/app`;

export const ICON_PATH = `${process.env.REACT_APP_BASE_S3_IMAGE}/app/icons`;

export const IS_MARTGO_APP = getUA.indexOf('mcoupon') > -1;

export const IS_DEV = process.env.REACT_APP_TYPE === 'DEV';
export const IS_STAGE = process.env.REACT_APP_TYPE === 'STAGE';
export const IS_PROD = process.env.REACT_APP_TYPE === 'PROD';

//local 테스트 용 기기정보 하드코딩
export const LOCAL_DEVICE_INFO = {
  device_id: '(null)',
  device_os: 'pc',
  model_nm: '',
  app_ver: '10.0.0',
  wdata: '',
  ukey: '',
};

// 전단 URL 체크
export const LEAFLET_URL_CHECK = (url: string): boolean => {
  if (url.includes('mlotte.net')) {
    return true;
  } else {
    return false;
  }
};

//슈퍼 오늘뭐먹지? 하드코딩 이벤트 id
export const SUPER_EVENT_ID =
  process.env.REACT_APP_TYPE === 'DEV' ? '101' : '1171146';

//슈퍼 전단 하드코딩
export const SUPER_LEAFLET_URL = 'https://www.mlotte.net/309?type=SUPER';

//슈퍼 하드코딩 체크
export const SUPER_CHECK = (str_cd: string): boolean => {
  if (str_cd === SUPER_INIT_DATA.str_cd) {
    return true;
  } else {
    return false;
  }
};

//슈퍼 하드코딩 점포정보
export const SUPER_INIT_DATA = {
  str_cd: 'ltsp',
  str_nm: 'SUPER',
  main_str_type: '4',
};

// 스캔앤고 L.PAY 노출 id 하드코딩
export const LPAY_OPEN_ID =
  process.env.REACT_APP_TYPE === 'DEV' ? 'mcoupon72' : 'uddnr';
// 스캔앤고 L.PAY 노출 id 체크 하드코딩
export const CHECK_LPAY_OPEN_ID = (userId: string): boolean => {
  if (userId === LPAY_OPEN_ID) {
    return true;
  } else {
    return false;
  }
};

// 스마트영수증 상세 점포명 표기 하드코딩-231228 김민수 대리님 요청
export const RECEIPT_TITLE_INFO = [
  {
    str_cd: '467',
    str_nm: '평촌점',
    str_title: '롯데쇼핑(주) 롯데식품관',
    hidden_logo: true,
  },
  {
    str_cd: '486',
    str_nm: 'TRU평촌점',
    str_title: '롯데쇼핑(주) 토이저러스',
    hidden_logo: true,
  },
];
