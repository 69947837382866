import styled, { css } from 'styled-components';
import { rem } from 'src/styles/function';
import { ICON_PATH } from 'src/utils/constants';

/**
 * @name 체크박스 스위치 버튼
 */
export const StyledCheckBoxSwitch = styled.div<{ isChecked }>`
  input[type='checkbox'] {
    /* display: none; */
    position: absolute;
    display: block;
    width: 1px;
    height: 1px;
    overflow: hidden;
    white-space: nowrap;
    clip: rect(1px 1px 1px 1px);
    clip-path: inset(1px);
    & + label {
      display: block;
      cursor: pointer;
      width: ${rem(40)};
      height: ${rem(24)};
      background: url(${ICON_PATH}/toggle-off.png) 0px 0px no-repeat;
      background-size: ${rem(40)} ${rem(24)};
      ${props => {
        return (
          props.isChecked &&
          css`
            background: url(${ICON_PATH}/toggle-on.png) 0px 0px no-repeat;
            background-size: ${rem(40)} ${rem(24)};
          `
        );
      }}
    }
  }
`;

/**
 * @name 체크박스 박스형 체크 버튼
 */
export const StyledCheckBoxCheck = styled.div<{ isChecked }>`
  position: relative;
  width: ${rem(16)};
  height: ${rem(16)};
  border-radius: ${rem(6)};
  padding: ${rem(4)};
  margin-right: ${rem(10)};
  border: 1px solid;
  border-color: #666666;
  background-color: white;
  ${props => {
    return (
      props.isChecked &&
      css`
        border-color: ${({ theme }) => theme.colors.red};
        background-color: ${({ theme }) => theme.colors.red};
      `
    );
  }}
  input[type='checkbox'] {
    /* display: none; */
    position: absolute;
    display: block;
    width: 1px;
    height: 1px;
    overflow: hidden;
    white-space: nowrap;
    clip: rect(1px 1px 1px 1px);
    clip-path: inset(1px);
    & + label {
      display: block;
      width: ${rem(16)};
      height: ${rem(16)};
      background: url('${ICON_PATH}/icon_check_off.png') no-repeat 0 0;
      background-size: 100% auto;
      text-align: center;
      position: absolute;
      left: ${rem(4)};
      top: ${rem(4)};
    }
  }
`;

/**
 * @name 체크박스 라인형 체크 버튼
 */
export const StyledCheckBoxCheckLine = styled.div<{ isChecked }>`
  position: relative;
  width: 100%;
  height: 100%;
  background-color: white;
  input[type='checkbox'] {
    /* display: none; */
    position: absolute;
    display: block;
    width: 1px;
    height: 1px;
    overflow: hidden;
    white-space: nowrap;
    clip: rect(1px 1px 1px 1px);
    clip-path: inset(1px);
    & + label {
      display: block;
      width: 100%;
      height: 100%;
      ${props => {
        switch (props.isChecked) {
          case true:
            return css`
              background: url('${ICON_PATH}/icon_check_red.png') no-repeat 0 0;
            `;
          case false:
            return css`
              background: url('${ICON_PATH}/icon_check_gray.png') no-repeat 0 0;
            `;
          default:
            return css``;
        }
      }}
      background-size: 100% auto;
    }
  }
`;
