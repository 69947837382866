import React from 'react';
import useSelectorEqual from 'src/hooks/useSelectorEqual';
import { RootState } from 'src/store/reducers';

import { bridgeOpenBrowser } from 'src/utils/bridge';
import { gaEvent } from 'src/utils/ga';
import { StyledSideMenuList } from '../styles';

function SideMenuContentsSection(): React.ReactElement {
  const [mainStoreState] = useSelectorEqual((state: RootState) => [
    state.user.mainStoreState,
  ]);

  const handleClickYouTude = () => {
    setGaEvent('유튜브');
    bridgeOpenBrowser(
      `https://www.youtube.com/channel/UCvcEiiSBO-D0QX8vDJs1qzQ`,
    );
  };
  const handleClickinstar = () => {
    setGaEvent('인스타그램');
    bridgeOpenBrowser(`https://www.instagram.com/lottemart_kr/`);
  };

  const setGaEvent = title => {
    if (mainStoreState.main_str_type_nm)
      gaEvent(
        `${title} 버튼`,
        mainStoreState.main_str_type_nm,
        '',
        '전체메뉴 팝업',
      );
  };

  return (
    <StyledSideMenuList>
      {mainStoreState.str_cd && (
        <ul>
          <span>롯데마트 콘텐츠</span>
          <li onClick={handleClickYouTude}>유튜브</li>
          <li onClick={handleClickinstar}>인스타그램</li>
        </ul>
      )}
      <div className="base_line" />
    </StyledSideMenuList>
  );
}

export default SideMenuContentsSection;
