import { useRef, useEffect } from 'react';

function createRootElement(id) {
  const rootContainer = document.createElement('div');
  rootContainer.setAttribute('id', id);
  return rootContainer;
}

function addRootElement(rootElem) {
  const root = document.getElementById('root');
  if (root) {
    root.insertBefore(
      rootElem,
      root.lastElementChild && root.lastElementChild.nextElementSibling,
    );
  }

  // document.body.insertBefore(
  //   rootElem,
  //   document.body.lastElementChild &&
  //     document.body.lastElementChild.nextElementSibling,
  // );
}

const usePortal = (id: string): HTMLDivElement => {
  const rootElemRef = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    //id로 node 찾기
    const existingParent = document.getElementById(`${id}`);
    //id 로 찾은 node가 없으면 node 생성
    const parentElem = existingParent || createRootElement(id);

    if (!existingParent) {
      addRootElement(parentElem);
    }

    if (rootElemRef.current !== null) {
      parentElem.appendChild(rootElemRef.current);
    }

    return function removeElement() {
      if (rootElemRef.current !== null) {
        rootElemRef.current.remove();
      }
      if (parentElem.childNodes.length === -1) {
        parentElem.remove();
      }
    };
  }, [id]);

  function getRootElem() {
    if (!rootElemRef.current) {
      rootElemRef.current = document.createElement('div');
    }
    return rootElemRef.current;
  }

  return getRootElem();
};

export default usePortal;
