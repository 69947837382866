import React from 'react';
import { StyledSelectBranch } from '../styles';

type TProps = {
  selectStoreType: string;
  onClickMartType: (mart) => void;
};

function SelectBranch(props: TProps): React.ReactElement {
  return (
    <StyledSelectBranch>
      <h3 className="sub_title">매장선택</h3>
      <ul>
        {STORE_TYPE.map((mart, i) => {
          return (
            <li
              key={i}
              className={mart.id === props.selectStoreType ? 'active' : ''}
              onClick={() => props.onClickMartType(mart)}
            >
              {mart.name}
            </li>
          );
        })}
      </ul>
    </StyledSelectBranch>
  );
}

export default SelectBranch;

export const STORE_TYPE = [
  {
    id: '1',
    name: '롯데마트',
  },
  {
    id: '2',
    name: 'ZETTAPLEX',
  },
  {
    id: '3',
    name: 'MAXX',
  },
  {
    id: '4',
    name: 'SUPER',
  },
];
