import React from 'react';
import { StyledBranchName } from '../styles';

type TProps = {
  children?: React.ReactNode;
};

function BranchName(props: TProps): React.ReactElement {
  return <StyledBranchName>{props.children}</StyledBranchName>;
}

export default BranchName;
