import styled, { css } from 'styled-components';
import { rem } from 'src/styles/function';
import { thejamsilFont } from 'src/styles/font';

/**
 * @name 기본 버튼
 * @param variant 버튼 스타일
 * @param buttonColor 버튼 색상
 */
export const StyledButton = styled.button<{ variant; buttonColor }>`
  width: 100%;
  height: ${rem(50)};
  text-align: center;
  border-radius: ${rem(10)};
  ${thejamsilFont('medium')}

  ${props => {
    switch (props.variant) {
      case 'outlined':
        return css`
          border: 1px solid;
          border-color: ${props.buttonColor};
          background-color: white;
          color: ${props.buttonColor};
        `;
      case 'contained':
        return css`
          background-color: ${props.buttonColor};
          color: ${props.buttonColor === '#ececec' ? '#666666' : 'white'};
        `;
      case 'text':
        return css`
          color: ${props.buttonColor};
        `;
      case 'switch':
        return css`
          position: absolute;
          top: 50%;
          right: 0;
          transform: translate(0%, -50%);
          width: ${rem(48)};
          height: ${rem(20)};
          background-color: #fff;
          border: 1px solid ${props.buttonColor};
          border-radius: ${rem(10)};
          text-align: left;
          padding-left: ${rem(2.5)};
          span {
            display: inline-block;
            width: ${rem(15)};
            height: ${rem(15)};
            background-color: ${props.buttonColor};
            border-radius: ${rem(7.5)};
            vertical-align: middle;
          }
        `;
      default:
        return css``;
    }
  }}
`;

/**
 * @name 기본 버튼 Wrapper (2개 이상일 경우 사용!!)
 * @prop count = 버튼 갯수
 */
export const StyledButtonGroup = styled.div<{ count: number }>`
  display: flex;
  justify-content: space-around;
  button {
    width: ${props => 100 / props.count - 2}% !important;
  }
`;
