import styled, { css } from 'styled-components';
import { TDimStyleProps } from '.';

/**
 * @name 팝업Dim 스타일
 * @params imageStyles 이미지 스타일
 */
export const StyledDim = styled.div<TDimStyleProps>`
  overflow: hidden;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  ${({ isDisplay = false }) => css`
    display: ${isDisplay ? 'block' : 'none'};
  `}
  align-items: center;
  justify-content: center;
  ${({ zIndex = 100 }) => css`
    z-index: ${zIndex};
  `}
`;
