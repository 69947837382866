import { action } from 'typesafe-actions';
import ActionTypes from '../constants/userConstats';
import { TMainStore, TUserInfo } from 'src/types/types';

export type UserAction =
  | {
      type: typeof ActionTypes.SET_MAINSTORE_STATE;
      payload: {
        mainStoreState: TMainStore;
      };
    }
  | {
      type: typeof ActionTypes.SET_TENANTSTORE_STATE;
      payload: {
        tenantStoreState: TMainStore;
      };
    }
  | {
      type: typeof ActionTypes.SET_USERINFO_STATE;
      payload: {
        userInfoState: TUserInfo;
      };
    }
  | {
      type: typeof ActionTypes.SET_CURRENT_TIME;
      payload: {
        currentTime: string;
      };
    };

export const actionSetMainStoreState = (
  mainStoreState: TMainStore,
): UserAction => {
  return action(ActionTypes.SET_MAINSTORE_STATE, { mainStoreState });
};

export const actionSetTenantStoreState = (
  tenantStoreState: TMainStore,
): UserAction => {
  return action(ActionTypes.SET_TENANTSTORE_STATE, { tenantStoreState });
};
export const actionSetUserInfoState = (
  userInfoState: TUserInfo,
): UserAction => {
  return action(ActionTypes.SET_USERINFO_STATE, { userInfoState });
};

export const actionSetCurrentTime = (currentTime: string): UserAction => {
  return action(ActionTypes.SET_CURRENT_TIME, { currentTime });
};
