import { thejamsilFont } from 'src/styles/font';
import { rem } from 'src/styles/function';
import styled from 'styled-components';

/**
 * @name 점포 이름 스타일 컴포넌트
 */
export const StyledBranchName = styled.h3`
  ${thejamsilFont('medium')};
  font-size: ${rem(14)};
  display: inline;
  margin-right: ${rem(10)};
`;

/**
 * @name 점포 검색 라벨 스타일 컴포넌트
 */
export const StyledBranchLabel = styled.div`
  display: inline-block;
  span {
    border: 1px solid black;
    ${thejamsilFont('regular')}
    font-size: ${rem(11)};
    border-radius: ${rem(2)};
    padding: ${rem(2)} ${rem(5)};
    box-sizing: border-box;
    vertical-align: middle;
    &.choice {
      border-color: ${({ theme }) => theme.colors.red};
      color: ${({ theme }) => theme.colors.red};
      margin-right: ${rem(5)};
    }
  }
`;

/**
 * @name 점포 검색 상세보기 버튼 스타일 컴포넌트
 */
export const StyledBranchDetailBtn = styled.button`
  ${thejamsilFont('medium')}
  font-size: ${rem(12)};
  position: absolute;
  bottom: ${rem(22)};
  right: ${rem(20)};
  p {
    display: inline-block;
    vertical-align: middle;
    width: ${rem(16)};
    height: ${rem(16)};
  }
`;

/**
 * @name 점포 주소 스타일 컴포넌트
 */
export const StyledBranchAddress = styled.div`
  font-size: ${rem(14)};
  margin: ${rem(11)} 0 ${rem(20)} 0;
  line-height: ${rem(22)};
`;

/**
 * @name 점포 운영시간,휴점일 스타일 컴포넌트
 */
export const StyledBranchDate = styled.div`
  div {
    margin-top: ${rem(10)};
    line-height: ${rem(15)};
    p {
      width: ${rem(16)};
      height: ${rem(16)};
      position: absolute;
      transform: translateY(-10%);
    }
    .txt {
      font-size: ${rem(12)};
      padding-left: ${rem(21)};
    }
  }
`;
