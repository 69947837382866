import React, { useEffect, useState } from 'react';
import {
  StyledCheckBoxSwitch,
  StyledCheckBoxCheck,
  StyledCheckBoxCheckLine,
} from './styles';

/**
 * @name 체크박스 스위치 버튼
 * @param id 버튼 id
 * @param isChecked 버튼 활성화 상태
 */

type CheckBoxSwichProps = {
  onChange: (checked: boolean, id: string) => void;
  id: string;
  isChecked: boolean;
};

export const CheckBoxSwitch = (
  props: CheckBoxSwichProps,
): React.ReactElement => {
  const handleChange = checked => {
    props.onChange(checked, props.id);
  };

  return (
    <StyledCheckBoxSwitch isChecked={props.isChecked}>
      <input
        type="checkbox"
        id={props.id}
        checked={props.isChecked}
        onChange={e => handleChange(e.target.checked)}
        aria-checked={props.isChecked}
      />
      <label htmlFor={props.id}></label>
    </StyledCheckBoxSwitch>
  );
};

/**
 * @name 체크박스 박스형 체크 버튼
 * @param id 버튼 id
 * @param isChecked 버튼 활성화 상태
 */

type CheckBoxCheckProps = {
  onChange: (checked: boolean, id: string) => void;
  id: string;
  isChecked?: boolean;
};

export const CheckBoxCheck = (
  props: CheckBoxCheckProps,
): React.ReactElement => {
  const [checked, setChecked] = useState(props.isChecked);

  const handleChange = checked => {
    props.onChange(checked, props.id);
    setChecked(checked);
  };

  useEffect(() => {
    setChecked(props.isChecked);
  }, [props.isChecked]);

  return (
    <StyledCheckBoxCheck isChecked={checked}>
      <input
        type="checkbox"
        id={props.id}
        checked={checked}
        onChange={e => handleChange(e.target.checked)}
      />
      <label htmlFor={props.id}></label>
    </StyledCheckBoxCheck>
  );
};

/**
 * @name 체크박스 선형 체크 버튼
 * @param id 버튼 id
 * @param isChecked 버튼 활성화 상태
 */

type CheckBoxCheckLineProps = {
  onChange: (checked: boolean, id: string) => void;
  id: string;
  isChecked?: boolean;
};

export const CheckBoxCheckLine = (
  props: CheckBoxCheckLineProps,
): React.ReactElement => {
  const [checked, setChecked] = useState(props.isChecked);

  const handleChange = checked => {
    props.onChange(checked, props.id);
    setChecked(checked);
  };

  useEffect(() => {
    setChecked(props.isChecked);
  }, [props.isChecked]);

  return (
    <StyledCheckBoxCheckLine isChecked={checked}>
      <input
        type="checkbox"
        id={props.id}
        checked={checked}
        onChange={e => handleChange(e.target.checked)}
      />
      <label htmlFor={props.id}></label>
    </StyledCheckBoxCheckLine>
  );
};
