import { E_POPUP_STATE } from 'src/utils/enum';
import ActionTypes from '../constants/receiptConstants';
import { TReceiptState, receiptAction } from '../types/receiptTypes';

export const initialState: TReceiptState = {
  isOpenReceiptPopup: E_POPUP_STATE.NONE,
  isOpenReceiptQrPopup: E_POPUP_STATE.NONE,
};

function ReceiptReducer(
  state: TReceiptState = initialState,
  action: receiptAction,
): TReceiptState {
  switch (action.type) {
    case ActionTypes.OPEN_RECEIPT_POPUP:
      return {
        ...state,
        isOpenReceiptPopup: E_POPUP_STATE.OPEN,
      };
    case ActionTypes.CLOSE_RECEIPT_POPUP:
      return {
        ...state,
        isOpenReceiptPopup: E_POPUP_STATE.CLOSE,
      };
    case ActionTypes.OPEN_RECEIPT_QR_POPUP:
      return {
        ...state,
        isOpenReceiptQrPopup: E_POPUP_STATE.OPEN,
      };
    case ActionTypes.CLOSE_RECEIPT_QR_POPUP:
      return {
        ...state,
        isOpenReceiptQrPopup: E_POPUP_STATE.CLOSE,
      };
    default:
      return state;
  }
}

export default ReceiptReducer;
