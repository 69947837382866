import { E_POPUP_STATE } from 'src/utils/enum';
import { TMyBenefitState } from '../types/myBenefitTypes';
import { MyBenefitAction } from '../actions/myBenefitAction';
import ActionTypes from '../constants/myBenefitContants';

export const initialState: TMyBenefitState = {
  isOpenMyBenefitNoticPopup: E_POPUP_STATE.NONE,
  issue_cd: '', // 더미 값
  cpn_cd: '', // 더미 값
  MyBenefitCouponList: [],
};

function MyBenefitReducer(
  state: TMyBenefitState = initialState,
  action: MyBenefitAction,
): TMyBenefitState {
  switch (action.type) {
    case ActionTypes.OPEN_MYBENEFIT_NOTICE_POPUP:
      return {
        ...state,
        isOpenMyBenefitNoticPopup: E_POPUP_STATE.OPEN,
      };
    case ActionTypes.CLOSE_MYBENEFIT_NOTICE_POPUP:
      return {
        ...state,
        isOpenMyBenefitNoticPopup: E_POPUP_STATE.CLOSE,
      };
    case ActionTypes.SET_ISSUE_AND_CPN_CD: {
      const { issue_cd, cpn_cd } = action;
      return {
        ...state,
        issue_cd,
        cpn_cd,
      };
    }
    case ActionTypes.SET_COUPON_LIST: {
      return {
        ...state,
        MyBenefitCouponList: action.payload.MyBenefitCouponList,
      };
    }

    default:
      return state;
  }
}

export default MyBenefitReducer;
