import React from 'react';
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';

import { StyledSideMenuList } from '../styles';
import { actionNoneMainMenu } from 'src/redux/actions/mainActions';
import useSelectorEqual from 'src/hooks/useSelectorEqual';
import { RootState } from 'src/store/reducers';
import { bridgeOpenLeaflet } from 'src/utils/bridge';
import { SUPER_CHECK, SUPER_LEAFLET_URL } from 'src/utils/constants';
import { gaEvent } from 'src/utils/ga';
import { getLeafletType } from 'src/utils/utility';

function SideMenuEventSection(): React.ReactElement {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [mainStoreState] = useSelectorEqual((state: RootState) => [
    state.user.mainStoreState,
  ]);

  const isSuper = SUPER_CHECK(mainStoreState.str_cd);

  const handleClickEvent = () => {
    setGaEvent('이벤트');
    navigate('/eventlist/ing');
    closePopup();
  };

  const handleClickMyBenefit = () => {
    setGaEvent('MY혜택');
    navigate('/mybenefit/coupon');
    closePopup();
  };

  const handleClickLeaflet = () => {
    setGaEvent('전단');

    // 전단 랜딩 분기처리
    const leafletTypeInfo = getLeafletType(mainStoreState);
    if (!leafletTypeInfo.pathname) {
      //슈퍼
      bridgeOpenLeaflet(SUPER_LEAFLET_URL);
    } else {
      navigate({
        pathname: leafletTypeInfo.pathname,
        search: leafletTypeInfo.search,
      });
      closePopup();
    }
  };

  const handleClickCommendProduct = () => {
    setGaEvent('추천상품');
    if (isSuper) {
      navigate('/super/recommendproduct');
    } else {
      navigate('/recommendproduct/new');
    }
    closePopup();
  };

  const handleClickKid = () => {
    setGaEvent('우리아이M혜택');
    navigate('/kid');
    closePopup();
  };

  const setGaEvent = title => {
    if (mainStoreState.main_str_type_nm)
      gaEvent(
        `${title} 버튼`,
        mainStoreState.main_str_type_nm,
        '',
        '전체메뉴 팝업',
      );
  };

  const closePopup = () => {
    dispatch(actionNoneMainMenu());
  };

  return (
    <StyledSideMenuList>
      {mainStoreState.str_cd && (
        <ul>
          <span>이벤트/혜택</span>
          <li onClick={handleClickEvent}>이벤트</li>
          <li onClick={handleClickMyBenefit}>MY혜택</li>
          <li onClick={handleClickLeaflet}>전단</li>
          <li onClick={handleClickCommendProduct}>추천상품</li>
          {!isSuper && <li onClick={handleClickKid}>우리아이M혜택</li>}
        </ul>
      )}
      <div className="base_line" />
    </StyledSideMenuList>
  );
}

export default SideMenuEventSection;
