import { TBranch, TBranchTerms } from 'src/types/types';

/**
 * 점포목록 필터링
 * @param branchTerms 조건
 * @param branchList 점포 목록
 */
export const getFilterBranchList = (
  branchTerms: TBranchTerms,
  branchList: TBranch[],
): TBranch[] => {
  if (branchList.length < 1) return [];

  const { main_str_type, region_cd, str_nm } = branchTerms;
  let filteredStoreType: TBranch[] = [];
  let filteredRegionCd: TBranch[] = [];
  let filteredStorehName: TBranch[] = [];

  // 매장유형 검색
  if (main_str_type) {
    filteredStoreType = branchList.filter(
      branch => branch.main_str_type === main_str_type,
    );
  } else {
    filteredStoreType = branchList;
  }

  // 지역 검색
  if (region_cd) {
    filteredRegionCd = branchList.filter(
      branch => branch.region_cd === region_cd,
    );
  } else {
    filteredRegionCd = branchList;
  }

  // 키워드 검색
  if (str_nm !== '' && str_nm !== 'SUPER') {
    filteredStorehName = branchList.filter(branch => {
      if (!branch.str_nm) return;
      return branch.str_nm.includes(str_nm.toUpperCase());
    });
  } else {
    filteredStorehName = branchList;
  }

  const selectStore = filteredStorehName
    .filter(item => filteredStoreType.includes(item))
    .filter(item => filteredRegionCd.includes(item));

  return selectStore;
};

/**
 * @name 휴점일 콤마 추가
 */
export const commfyHoliday = (
  holi1?: string,
  holi2?: string,
  holi3?: string,
): string => {
  const holidayList = [holi1, holi2, holi3];
  let holiday;

  holidayList.map(day => {
    if (!day) return;
    return (holiday = holiday ? holiday + ',' + day : day);
  });

  return holiday;
};
