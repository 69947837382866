import React from 'react';
import { StyledSelectResultSuper } from '../styles';

function SelectResultSuper(): React.ReactElement {
  return (
    <StyledSelectResultSuper>
      <h3>주 이용 점포를 슈퍼로 선택하셨습니다.</h3>
    </StyledSelectResultSuper>
  );
}

export default SelectResultSuper;
