import { action } from 'typesafe-actions';
import ActionTypes from '../constants/myBenefitContants';
import { TCouponListItem } from 'src/redux/types/CouponTypes';

export type MyBenefitAction =
  | {
      type: typeof ActionTypes.OPEN_MYBENEFIT_NOTICE_POPUP;
    }
  | {
      type: typeof ActionTypes.CLOSE_MYBENEFIT_NOTICE_POPUP;
    }
  | {
      type: typeof ActionTypes.SET_ISSUE_AND_CPN_CD;
      issue_cd: string;
      cpn_cd: string;
    }
  | {
      type: typeof ActionTypes.SET_COUPON_LIST;
      payload: {
        MyBenefitCouponList: TCouponListItem[];
      };
    };
export const actionOpenMyBenefitNoticePopup = (): MyBenefitAction => {
  return action(ActionTypes.OPEN_MYBENEFIT_NOTICE_POPUP);
};

export const actionCloseMyBenefitNoticePopup = (): MyBenefitAction => {
  return action(ActionTypes.CLOSE_MYBENEFIT_NOTICE_POPUP);
};

export const actionSetIssueAndCpnCd = (
  issue_cd: string,
  cpn_cd: string,
): MyBenefitAction => {
  return {
    type: ActionTypes.SET_ISSUE_AND_CPN_CD,
    issue_cd,
    cpn_cd,
  };
};

export const actionSetMybenefitCouponList = (
  MyBenefitCouponList: TCouponListItem[],
): MyBenefitAction => {
  return action(ActionTypes.SET_COUPON_LIST, { MyBenefitCouponList });
};
