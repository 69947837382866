import React from 'react';
import { StyledSideMenuHeader } from '../styles';
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import {
  actionCloseMainMenu,
  actionNoneMainMenu,
} from 'src/redux/actions/mainActions';
import { gaEvent } from 'src/utils/ga';

function SideMenuHeader(): React.ReactElement {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const handleClickSetting = () => {
    gaEvent('설정', '', '', '전체메뉴 팝업');
    dispatch(actionNoneMainMenu());
    navigate('/setting');
  };
  return (
    <StyledSideMenuHeader>
      <button
        type="button"
        className="btn_setting"
        onClick={() => handleClickSetting()}
      ></button>
      <button
        type="button"
        className="btn_close"
        onClick={() => dispatch(actionCloseMainMenu())}
      ></button>
    </StyledSideMenuHeader>
  );
}

export default SideMenuHeader;
