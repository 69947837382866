import { action } from 'typesafe-actions';

import ActionTypes from '../constants/mainConstants';

export type MainAction =
  | {
      type: typeof ActionTypes.OPEN_MAIN_MENU;
    }
  | {
      type: typeof ActionTypes.CLOSE_MAIN_MENU;
    }
  | {
      type: typeof ActionTypes.NONE_MAIN_MENU;
    }
  | {
      type: typeof ActionTypes.OPEN_MART_SEARCH_POPUP;
    }
  | {
      type: typeof ActionTypes.CLOSE_MART_SEARCH_POPUP;
    };

export const actionOpenMainMenu = (): MainAction => {
  return action(ActionTypes.OPEN_MAIN_MENU);
};
export const actionCloseMainMenu = (): MainAction => {
  return action(ActionTypes.CLOSE_MAIN_MENU);
};
export const actionNoneMainMenu = (): MainAction => {
  return action(ActionTypes.NONE_MAIN_MENU);
};

export const actionOpenMartSearchPopup = (): MainAction => {
  return action(ActionTypes.OPEN_MART_SEARCH_POPUP);
};

export const actionCloseMartSearchPopup = (): MainAction => {
  return action(ActionTypes.CLOSE_MART_SEARCH_POPUP);
};
