import React, { useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { actionNoneMainMenu } from 'src/redux/actions/mainActions';
import { useQueries } from '@tanstack/react-query';
import useSelectorEqual from 'src/hooks/useSelectorEqual';

import { apiGetLpoint } from 'src/api/apiUser';
import { apiGetSnowplanBalance } from 'src/api/apiSnowPlan';
import {
  actionCloseSnowPlanUsePopup,
  actionOpenSnowPlanUsePopup,
} from 'src/redux/actions/appActions';
import { RootState } from 'src/store/reducers';
import { commaDate, commafy, getSuperGradeName } from 'src/utils/utility';
import { IMAGE_ROOT, SUPER_CHECK } from 'src/utils/constants';
import { gaEvent } from 'src/utils/ga';
import { IcoArrowBlack, IconArrowWhiteRound } from 'src/components/Icon';
import { Button } from 'src/components/Button';
import {
  StyledSideMenuBenefit,
  StyledSideMenuUserInfo,
  StyledSideMenutPointSection,
} from '../styles';
import { E_QUERY_KEY } from 'src/utils/queryKey';

export function SideMenuInfo(): React.ReactElement {
  const [userInfoState, mainStoreState] = useSelectorEqual(
    (state: RootState) => [state.user.userInfoState, state.user.mainStoreState],
  );
  const navigate = useNavigate();
  const dispatch = useDispatch();

  // Lpoint, 스노우플랜 조회
  const queries = useQueries({
    queries: [
      {
        queryKey: E_QUERY_KEY.USER_DETAIL_LPOINT,
        queryFn: apiGetLpoint,
      },
      {
        queryKey: E_QUERY_KEY.SNOWPLAN_DETAIL_BALAMCE,
        queryFn: apiGetSnowplanBalance,
      },
    ],
  });

  // lpoint
  const lpointData = useMemo(() => {
    return queries[0].data;
  }, [queries]);
  // 스노우플랜
  const snowPointBalance = useMemo(() => {
    return queries[1].data;
  }, [queries]);

  const handleClickPoint = () => {
    setGaEvent('포인트');
    dispatch(actionNoneMainMenu());
    navigate('/point');
  };

  //스노우플랜 사용하기 클릭
  const handleclickSnowPointUse = () => {
    setGaEvent('스노우플랜 사용하기');

    if (!snowPointBalance || snowPointBalance === '0') {
      alert('스노우플랜 잔액이 없습니다.');
      dispatch(actionCloseSnowPlanUsePopup());
    } else {
      dispatch(actionOpenSnowPlanUsePopup());
    }
  };

  // 등급별 제휴 혜택 이용방법 클릭
  const handleClickBenefitBtn = () => {
    setGaEvent('등급별 제휴 혜택 이용방법');
    navigate('/point/benefit');
    dispatch(actionNoneMainMenu());
  };

  const setGaEvent = title => {
    if (mainStoreState.main_str_type_nm)
      gaEvent(
        `${title} 버튼`,
        mainStoreState.main_str_type_nm,
        '',
        '전체메뉴 팝업',
      );
  };

  const grade_nm = getSuperGradeName(
    userInfoState.super_grade,
    userInfoState.super_grade_nm,
    mainStoreState.str_cd,
    userInfoState.member_grade_nm,
  );

  return (
    <>
      {mainStoreState.str_cd && (
        <>
          <StyledSideMenuUserInfo grade={grade_nm}>
            <div className="grade"></div>
            <div className="user_info">
              <span>
                <strong> {userInfoState.member_name}</strong>
                {userInfoState.emp_yn === 'Y' ? ' 임직원님' : ' 님'} 즐거운 쇼핑
                되세요!{' '}
              </span>
              <p className="join">
                가입일 : {commaDate(userInfoState.join_dt)}
              </p>
            </div>
          </StyledSideMenuUserInfo>
          {!SUPER_CHECK(mainStoreState.str_cd) && (
            <StyledSideMenuBenefit
              role="button"
              onClick={handleClickBenefitBtn}
            >
              <div className="box">
                <h2>등급별 제휴 혜택 이용방법</h2>
                <ul>
                  <li>
                    <img
                      src={`${IMAGE_ROOT}/default/menu_center.png`}
                      alt="롯데마트 문화센터"
                    />
                  </li>
                  <li>
                    <img
                      src={`${IMAGE_ROOT}/default/menu_super.png`}
                      alt="롯데슈퍼"
                    />
                  </li>
                  <li className="last_option">
                    <img
                      src={`${IMAGE_ROOT}/default/menu_cinema.png`}
                      alt="롯데시네마"
                    />
                  </li>
                </ul>
              </div>
              <div className="icon">
                <i>
                  <IconArrowWhiteRound />
                </i>
              </div>
            </StyledSideMenuBenefit>
          )}
          <StyledSideMenutPointSection>
            <div className="point_info_box">
              <div>
                <div className="point_name" onClick={handleClickPoint}>
                  L.POINT
                  <div className="ico">
                    <IcoArrowBlack />
                  </div>
                </div>
                <div className="lpoint" onClick={handleClickPoint}>
                  <div className="point">
                    {queries[0].isSuccess ? (
                      <>
                        {lpointData
                          ? commafy(lpointData.membs_use_psbt_point)
                          : 0}
                        p
                      </>
                    ) : (
                      '-'
                    )}
                  </div>
                </div>
              </div>
              {!SUPER_CHECK(mainStoreState.str_cd) && (
                <>
                  <div>
                    <div className="point_name" onClick={handleClickPoint}>
                      SNOWPLAN
                      <div className="ico">
                        <IcoArrowBlack />
                      </div>
                    </div>
                    <div className="point">
                      {queries[1].isSuccess ? (
                        <>
                          <div className="point_num" onClick={handleClickPoint}>
                            {snowPointBalance ? commafy(snowPointBalance) : 0}p
                          </div>
                          <Button
                            text="사용하기"
                            buttonColor="blue"
                            variant="outlined"
                            onClick={handleclickSnowPointUse}
                          />
                        </>
                      ) : (
                        '-'
                      )}
                    </div>
                  </div>
                </>
              )}
            </div>
          </StyledSideMenutPointSection>
        </>
      )}
      <div className="base_line" />
    </>
  );
}
