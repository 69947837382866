import React from 'react';
import { useMutation } from '@tanstack/react-query';
import { StyledBranchCardWrapper } from '../styles';
import BranchName from 'src/components/BranchSearchPopup/components/BranchName';

import BranchDetailBtn from 'src/components/BranchSearchPopup/components/BranchDetailBtn';
import BranchAddress from 'src/components/BranchSearchPopup/components/BranchAddress';
import BranchDate from 'src/components/BranchSearchPopup/components/BranchDate';
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { actionCloseMartSearchPopup } from 'src/redux/actions/mainActions';
import { actionCloseConfirmPopup } from 'src/redux/actions/appActions';
import BranchLabel from 'src/components/BranchSearchPopup/components/BranchLabel';
import { TBranch, TMainStore } from 'src/types/types';
import { actionSetMainStoreState } from 'src/redux/actions/userActions';
import { apiSetChangeMainStore } from 'src/api/apiUser';
import { gaEvent } from 'src/utils/ga';
import { actionSetMybenefitCouponList } from 'src/redux/actions/myBenefitAction';

type TProps = {
  branch: TBranch;
  mainStore: TMainStore;
  onChangeBranch: (onConfirm, branch) => void;
};

function BranchCard(props: TProps): React.ReactElement {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  // 주이용점포 변경
  const setMainStore = useMutation(apiSetChangeMainStore, {
    onSuccess: () => {
      dispatch(actionSetMainStoreState(props.branch));
      dispatch(actionSetMybenefitCouponList([])); //점포변경 시 쿠폰목록 초기화
    },
    onError: () => {
      alert(
        '주이용점포 변경 중 에러가 발생하였습니다.\n잠시후 다시 시도해주세요.',
      );
    },
  });

  // 예 클릭시
  const handleClickConfirm = () => {
    // 주이용점포 변경 및 팝업 닫기
    setMainStore.mutate(props.branch.str_cd);
    dispatch(actionCloseMartSearchPopup());
    dispatch(actionCloseConfirmPopup());
  };

  // 매장 선택시
  const handleClickMartCard = () => {
    // 선택한 매장이 주이용점포일 경우 예외처리
    if (props.branch.str_cd === props.mainStore.str_cd) return;
    props.onChangeBranch(handleClickConfirm, props.branch);
  };

  // 상세보기 클릭시
  const handleClickMartDetail = e => {
    gaEvent(
      '점포 상세보기',
      props.branch.str_cd,
      props.branch.str_nm,
      '주이용점포 팝업',
    );
    e.stopPropagation();
    navigate(`/martdetail?strCd=${props.branch.str_cd}`);
    // 팝업 닫기
    dispatch(actionCloseMartSearchPopup());
    // 매장찾기 페이지에서 뒤로가기 시 주이용점포 팝업 다시 열기
    sessionStorage.setItem('martSearchPoupupOpen', 'Y');
  };

  return (
    <StyledBranchCardWrapper onClick={handleClickMartCard}>
      <BranchName>{props.branch.str_nm}</BranchName>
      <BranchLabel branch={props.branch} />
      {(props.branch.addr1 || props.branch.addr2) && (
        <BranchAddress>
          {props.branch.addr1} {props.branch.addr2}
        </BranchAddress>
      )}
      <BranchDate branch={props.branch} />
      <BranchDetailBtn onClick={handleClickMartDetail} />
    </StyledBranchCardWrapper>
  );
}

export default BranchCard;
