import { thejamsilFont } from 'src/styles/font';
import { rem } from 'src/styles/function';
import { ICON_PATH } from 'src/utils/constants';
import styled, { css, keyframes } from 'styled-components';

/**
 * @name 밑으로 위로 올라오는 팝업 키프레임
 */
export const KeyframeSlideUpPopup = keyframes`
  0%{
    transform: translateY(0);
  }
  100%{
    transform: translateY(calc(-100% + 1px));
  }
`;
export const KeyframeSlideDownPopup = keyframes`
  0%{
    transform: translateY(-100%);
  }
  100%{
    transform: translateY(0);
  }
`;

/**
 * @name 밑에서 위로 올라오는 팝업 스타일 컴포넌트
 */
export const StyledSlideUpPopupWrapper = styled.div<{
  zIndex?: number;
  overflow?: string;
}>`
  position: fixed;
  overflow: ${props =>
    props.overflow && props.overflow === 'N' ? '' : 'hidden'};
  z-index: ${({ zIndex }) => (zIndex ? zIndex : 201)};
  top: 100%;
  left: 0;
  max-height: 95vh;
  width: 100%;
  background-color: white;
  border-radius: 1.5rem 1.5rem 0 0;
  &.open {
    animation: ${KeyframeSlideUpPopup} 0.5s 0s ease 1 forwards;
  }
  &.close {
    animation: ${KeyframeSlideDownPopup} 0.5s 0s ease 1 backwards;
  }
`;

/**
 * @name 밑으로 위로 올라오는 팝업 키프레임
 */
export const KeyframeSlideUpFullScreenPopup = keyframes`
  0%{
    transform: translateY(100%);
  }
  100%{
    transform: translateY(0%);
  }
`;
export const KeyframeSlideDownFullScreenPopup = keyframes`
  0%{
    transform: translateY(0%);
  }
  100%{
    transform: translateY(100%);
  }
`;

/**
 * @name 밑에서 위로 올라오는 풀스크린 팝업 스타일 컴포넌트
 */
export const StyledSlideUpFullScreenPopupWrapper = styled.div<{
  zIndex?: number;
}>`
  position: fixed;
  overflow: hidden;
  z-index: ${props => (props.zIndex ? props.zIndex : 201)};
  top: 0%;
  width: 100%;
  background-color: white;
  &.open {
    animation: ${KeyframeSlideUpFullScreenPopup} 0.5s 0s ease 1 forwards;
  }
  &.close {
    animation: ${KeyframeSlideDownFullScreenPopup} 0.5s 0s ease 1 backwards;
  }
`;

/**
 * @name 중앙 팝업 스타일 컴포넌트
 */
export const StyledCenteredPopupWrapper = styled.div<{
  padding?: string;
  zIndex?: number;
  isBanner?: boolean;
}>`
  z-index: ${props => (props.zIndex ? props.zIndex : 301)};
  box-sizing: border-box;
  display: block;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 90%;
  @media all and (min-aspect-ratio: 3/4) {
    max-width: 50%;
    max-height: 90%;
  }
  ${props =>
    !props.isBanner &&
    css`
      background: white;
      border-radius: ${rem(20)};
      padding: ${props.padding
        ? props.padding
        : `${rem(50.5)} ${rem(20)} ${rem(20)}`};
    `}
`;

/**
 * @name 확인/취소 팝업
 */
export const StyledConfirmPopupWrapper = styled.div<{
  isBanner?: boolean;
  padding?: string;
}>`
  background-color: white;
  border-radius: ${rem(20)};
  ${props =>
    props.isBanner &&
    css`
      margin-top: ${rem(20)};
      box-sizing: border-box;
      padding: ${props.padding
        ? props.padding
        : `${rem(50.5)} ${rem(20)} ${rem(20)}`};
    `}
`;

/**
 * @name 팝업 헤더 스타일 컴포넌트
 */
export const StyledPopupHeader = styled.div`
  border-radius: 1.5rem 1.5rem 0 0;
  background-color: white;
  width: 100%;
  box-sizing: border-box;
  h3 {
    margin: ${rem(22)} ${rem(30)} ${rem(25.5)} ${rem(20)};
    ${thejamsilFont('regular')};
    font-size: 20px;
    float: left;
  }
  .ico_close_wrapper {
    width: 2rem;
    height: 2rem;
    position: absolute;
    top: 0.9rem;
    right: 1rem;
  }
`;

/**
 * @name 팝업 메시지 공통 스타일 컴포넌트
 */
export const StyledPopupMessage = styled.div`
  p {
    font-size: ${rem(14)};
    line-height: ${rem(24)};
    text-align: center;
    strong {
      font-size: ${rem(16)};
      ${thejamsilFont('medium')};
      color: #e60012;
    }
    .title {
      font-size: ${rem(16)};
      ${thejamsilFont('medium')};
    }
  }
  .sub_txt {
    font-size: ${rem(12)};
    color: #666666;
    text-align: left;
  }
`;

/**
 * @name 스노우플랜 엘포인트 전환 팝업 메시지
 */
export const StyledPopupSnowMessage = styled.div`
  p {
    font-size: ${rem(14)};
    line-height: ${rem(24)};
    text-align: left;
    strong {
      font-size: ${rem(16)};
      ${thejamsilFont('medium')};
      color: #e60012;
    }
    .title {
      font-size: ${rem(16)};
      ${thejamsilFont('medium')};
    }
  }
  .sub_txt {
    font-size: ${rem(12)};
    color: #666666;
    text-align: left;
  }
`;

/**
 * @name 확인/취소 팝업 배너 스타일 컴포넌트
 */
export const StyledConfirmPopupBanner = styled.div`
  z-index: 302;
  width: 100%;
  img {
    width: 100%;
  }
`;

/**
 * @name 확인/취소 팝업 스타일 컴포넌트
 */
export const StyledConfirmPopup = styled.div`
  margin-bottom: ${rem(35)};
  p {
    line-height: 1.3;
  }
`;

/**
 * @name 확인 팝업 내용 스타일 컴포넌트
 */
export const StyledAlertPopupContent = styled.div`
  text-align: center;
  font-size: ${rem(14)};
  margin: 0 0 ${rem(36.5)} 0;
  p {
    line-height: 1.3;
  }
`;

/**
 * @name 메인팝업 스타일 컴포넌트
 */
export const StyledMainPopupContent = styled.div`
  z-index: 203;
  .swiper_area {
    .swiper {
      border-radius: ${rem(20)} ${rem(20)} 0 0;
    }
    .swiper-pagination {
      position: absolute;
      bottom: ${rem(10)};
    }
    .swiper-pagination-bullet {
      background-color: #adadad;
      width: ${rem(11)};
      height: ${rem(11)};
      opacity: 100%;
    }
    .swiper-pagination-bullet-active {
      background-color: black;
    }
  }
  .btn_area {
    padding: ${rem(15)};
    .check_area {
      display: flex;
      padding-bottom: ${rem(10)};
      p {
        margin: auto 0;
        color: ${({ theme }) => theme.colors.gray02};
        font-size: ${rem(16)};
      }
    }
    button {
      font-size: ${rem(16)};
    }
  }
`;

/**
 * @name 웰컴팝업 스타일 컴포넌트
 */
export const StyledWelcomePopup = styled.div`
  position: fixed;
  overflow: hidden;
  z-index: 810;
  top: 0%;
  width: 100%;
  height: 100%;
  background-color: white;
  box-sizing: border-box;
  .popup_content {
    margin: 0 2rem;
    position: absolute;
    top: 50%;
    transform: translate(0, -50%);
    text-align: center;
    h3 {
      ${thejamsilFont('bold')};
      font-size: ${rem(32)};
      color: #da291c;
      margin-bottom: ${rem(20)};
    }
    .content {
      margin-bottom: ${rem(39.5)};
      .content_desc {
        font-size: ${rem(16)};
        line-height: ${rem(24)};
        margin-bottom: ${rem(13)};
        strong {
          ${thejamsilFont('regular')}
        }
      }
    }
  }
  ul {
    display: flex;
    margin-bottom: ${rem(59.5)};
  }
  button {
    ${thejamsilFont('regular')};
    font-size: ${rem(16)};
  }
  .login_area {
    display: flex;
    justify-content: space-evenly;
    margin-top: ${rem(30)};
    div {
      display: flex;
      align-items: center;
      p {
        font-size: ${rem(14)};
        color: #1c1c1c;
      }
    }
  }
`;

/**
 * @name 로딩팝업 스타일 컴포넌트
 */
const logo_moving2 = keyframes`
  0% {
    background-position-x: 0px;
  }
  10% {
    background-position-x: -100px;
  }
  20% {
    background-position-x: -200px;
  }
  30% {
    background-position-x: -300px;
  }
  40% {
    background-position-x: -400px;
  }
  50% {
    background-position-x: -500px;
  }
  60% {
    background-position-x: -600px;
  }
  70% {
    background-position-x: -700px;
  }
  80% {
    background-position-x: -800px;
  }
  90% {
    background-position-x: -900px;
  }
  100% {
    background-position-x: -1000px;
  }
`;

export const StyledLodingPopup = styled.div`
  position: fixed;
  overflow: hidden;
  z-index: 1000;
  top: 0%;
  width: 100%;
  height: 100%;
  background-color: white;
  opacity: 0.85;
  .loading {
    position: absolute;
    z-index: 220;
    left: 50%;
    top: 50%;
    width: 100px;
    height: 100px;
    transform: translateX(-50%) translateY(-50%);
    .loading_ico {
      display: block;
      background-image: url('${ICON_PATH}/icon_loading.png');
      width: 100px;
      height: 100px;
      background-repeat: no-repeat;
      background-position: 0 0;
      transform: scale(0.5);
      animation: ${logo_moving2} 2s steps(1, end) infinite forwards;
    }
  }
`;

/**
 * @name 인트로 팝업 스타일 컴포넌트
 */
export const StyledIntroPopup = styled.div`
  position: fixed;
  overflow: hidden;
  z-index: 710;
  top: 0%;
  width: 100%;
  height: 100vh;
  .swiper-pagination {
    position: fixed;
    bottom: 8vh;
  }
  .swiper-pagination-bullet {
    background-color: #adadad;
    width: ${rem(11)};
    height: ${rem(11)};
    opacity: 100%;
  }
  .swiper-pagination-bullet-active {
    background-color: black;
  }
  .intro_img {
    margin: 0 ${rem(20)};
    position: relative;
    transform: translateY(-53%);
    margin-top: 50vh;
    background-color: white;
    padding: ${rem(20)} ${rem(30)} ${rem(50)};
    border-radius: ${rem(20)};
    img {
      height: 100%;
    }
  }
  .last_slide_wrapper {
    margin: 0;
    width: 100%;
    height: 100%;
    position: relative;
    .last_img {
      position: relative;
      height: 100vh;
      background-color: #4c4c4c;
    }
    .btn_area {
      position: absolute;
      left: 0;
      right: 0;
      bottom: ${rem(20)};
      width: 90%;
      margin: auto;
      z-index: 300;
      button {
        font-size: ${rem(18)};
        height: ${rem(64)};
      }
    }
  }
`;

/**
 * @name 응모 팝업 이벤트 컴포넌트
 */
export const StyledEventPopupApply = styled.section`
  .apply_popup {
    display: flex;
    flex-direction: column;
    gap: ${rem(20)};
  }
`;

/**
 * @name 응모 팝업 이벤트 컴포넌트
 */
export const StyledEventPopupFinishApply = styled.section`
  .apply_finish {
    & > :nth-child(1) {
      margin-bottom: ${rem(30)};
    }
    .finish_msg {
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
`;

/**
 * @name 누적 팝업 이벤트 컴포넌트
 */
export const StyledEventPopupFinishAccrue = styled.section`
  .accrue_finish {
    & > :nth-child(1) {
      margin-bottom: ${rem(30)};
    }

    .accrue_msg {
      display: flex;
      align-items: center;
      justify-content: center;
    }
    .finish_button {
      display: flex;
      gap: ${rem(10)};
    }
  }
`;

/**
 * @name 출석 팝업 이벤트 컴포넌트
 */
export const StyledEventPopupAttend = styled.section`
  .apply_finish {
    & > :nth-child(1) {
      margin-bottom: ${rem(30)};
    }

    .finish_msg {
      display: flex;
      align-items: center;
      justify-content: center;
    }
    .finish_button {
      display: flex;
      gap: ${rem(10)};
    }
  }
`;

/**
 * @name 스캔앤고 인트로 팝업 스타일 컴포넌트
 */
export const StyledScangoIntroPopupContent = styled.section`
  z-index: 203;
  .swiper_area {
    position: relative;
    overflow: hidden;
    border-radius: ${rem(20)} ${rem(20)} 0 0;
    z-index: 0;
    isolation: isolate;
    .swiper {
      overflow: visible;
      margin-bottom: ${rem(23)};
      border-radius: ${rem(20)} ${rem(20)} 0 0;
    }
    .swiper-pagination {
      position: absolute;
      display: block;
      bottom: ${rem(-23)};
      width: 100%;
    }
    .swiper-pagination-bullet {
      background-color: #adadad;
      width: ${rem(11)};
      height: ${rem(11)};
      opacity: 100%;
    }
    .swiper-pagination-bullet-active {
      background-color: black;
    }
  }
  .btn_area {
    padding: ${rem(15)};
    .check_area {
      display: flex;
      padding-bottom: ${rem(10)};
      p {
        margin: auto 0;
        color: ${({ theme }) => theme.colors.gray02};
        font-size: ${rem(16)};
      }
    }
    button {
      font-size: ${rem(16)};
    }
  }
`;

/**
 * @name 주이용점포 설정 유도 팝업 스타일 컴포넌트
 */
export const StyledMartSettingPopupWraaper = styled.div`
  .btn_area {
    margin: ${rem(10)} ${rem(5)};
  }
`;

/**
 * @name 스노우플랜 팝업 스타일 컴포넌트
 */
export const StyledSnowPlanPopupWrapper = styled.div`
  position: relative;
  .popup_header_area {
    padding: ${rem(20)} ${rem(20)} 0;
    display: flex;
    justify-content: space-between;
    margin-bottom: ${rem(10)};
    .check_area {
      margin-left: ${rem(4)};
      display: flex;
      p {
        margin: auto 0;
        color: ${({ theme }) => theme.colors.gray02};
        font-size: ${rem(16)};
      }
    }
    .close_btn {
      align-items: center;
      display: flex;
      margin-left: auto;
      i {
        width: ${rem(30)};
        height: ${rem(30)};
      }
    }
  }
  .popup_body_area {
    margin: auto;
    padding: 0 ${rem(20)} ${rem(20)};
    max-width: 26rem;
    max-height: calc(80vh - ${rem(60)});
    overflow-y: auto;
  }
  .log_area {
    text-align: center;
    padding: 0 ${rem(35)};
  }
  .dec_area {
    display: flex;
    justify-content: center;
    margin: ${rem(15)} 0;
    p {
      ${thejamsilFont('regular')};
      font-size: ${rem(15)};
      color: ${({ theme }) => theme.colors.black};
      strong {
        ${thejamsilFont('medium')};
        color: ${({ theme }) => theme.colors.blue};
      }
    }
  }
  .info_area {
    margin: 0 ${rem(30)} ${rem(20)};
    .point_box {
      border-radius: ${rem(15)};
      height: ${rem(32)};
      display: flex;
      justify-content: space-between;
      padding: 0 ${rem(15)};
      align-items: center;
      margin-bottom: ${rem(7)};
      &.expire {
        background-color: ${({ theme }) => theme.colors.red};
      }
      &.useful {
        background-color: ${({ theme }) => theme.colors.blue};
      }
      &.order {
        background-color: ${({ theme }) => theme.colors.gray03};
      }
      .text {
        ${thejamsilFont('regular')};
        font-size: ${rem(16)};
        color: ${({ theme }) => theme.colors.white};
      }
      .point {
        color: ${({ theme }) => theme.colors.white};
        font-size: ${rem(16)};
        ${thejamsilFont('medium')};
      }
    }
  }
  .banner_area {
    margin: ${rem(25)} ${rem(20)};
    .banner {
      background-color: #eff0f0;
      display: flex;
      border-radius: ${rem(10)};
      max-width: ${rem(400)}; /* Set a maximum width for the banner container */
      overflow: hidden; /* Hide overflow content */
      min-height: ${rem(70)};
      padding: ${rem(10)} 0 ${rem(10)} ${rem(10)};
      align-items: center;
      justify-content: space-between; /* Adjusted to space-between */
      .text {
        font-size: ${rem(14)};
        ${thejamsilFont('regular')};
        line-height: ${rem(25)};
        strong {
          ${thejamsilFont('medium')};
          color: ${({ theme }) => theme.colors.blue};
        }
      }
    }
    .img {
      margin-left: auto;
      margin-bottom: ${rem(-25)}; /* Reset the margin-bottom */
      max-width: ${rem(100)}; /* Set a specific width for the image */
      height: auto;
    }
  }
`;

/**
 * @name 스노우플랜페스타 적립 이벤트 팝업 스타일 컴포넌트
 */
export const StyledSnowPlanFestaPopup = styled.div`
  .img_area {
    margin-bottom: ${rem(20)};
  }

  .btn_area {
    ${thejamsilFont('medium')};
    position: relative;
    button {
      font-size: ${rem(16)};
    }
    .btn_area_count {
      color: #fff;
      position: absolute;
      right: ${rem(20)};
      top: 50%;
      transform: translate(0, -50%);
      span {
        ${thejamsilFont('bold')};
        font-size: ${rem(24)};
      }
    }
  }
`;

export const StyledInspection = styled.div`
  padding: ${rem(60)} ${rem(40)};
  position: fixed;
  overflow: scroll;
  z-index: 920;
  top: 0%;
  width: 100%;
  height: 100%;
  background-color: white;
  box-sizing: border-box;
  .title {
    .grid {
      display: grid;
      grid-template-columns: auto ${rem(100)};
      grid-template-rows: auto;
      .grid_text {
        ${thejamsilFont('medium')}
        font-size: ${rem(24)};
        top: 1rem;
        position: relative;
        h1 {
          color: ${({ theme }) => theme.colors.red};
          margin-top: ${rem(12)};
        }
      }

      img {
        width: ${rem(100)};
        height: ${rem(100)};
      }
    }
    .grid_desc {
      ${thejamsilFont('light')}
      font-size: ${rem(20)};
      line-height: ${rem(24)};
      letter-spacing: -0.05rem;
      text-align: center;
      margin: 2rem 0;
      h3 {
        font-size: ${rem(18)};
        ${thejamsilFont('regular')}
        margin-bottom : 1rem;
      }
      p {
        font-size: ${rem(14)};
        line-height: 1.5;
        color: #666;
      }
    }
  }

  .contents {
    ${thejamsilFont('light')}
    font-size: ${rem(14)};
    height: auto;
    background-color: #fff;
    box-shadow: 0px 1px 15px 1px rgba(0, 0, 0, 0.1);
    margin: 0 auto;
    border-radius: 0.5rem;
    ul {
      li {
        padding: 1rem;
        p {
          text-align: center;
          span {
            ${thejamsilFont('regular')}
          }
          font-size: ${rem(14)};
          line-height: 1.5;
        }
        .barcode {
          width: 100%;
          height: ${rem(100)};
          margin: auto;
          svg {
            width: 100%;
          }
        }
      }
      li:not(:last-child) {
        border-bottom: solid 1px #e6e6e6;
      }
    }
  }
  button {
    ${thejamsilFont('regular')}
    font-size: ${rem(16)};
    margin-top: 2rem;
  }
  .logo {
    width: ${rem(100)};
    margin: 0 auto;
    padding-top: 9rem;
    img {
      width: 100%;
    }
  }
`;
