import React from 'react';
import { StyledCenteredPopupWrapper } from '../styles';

type Props = {
  children?: React.ReactNode;
  padding?: string;
  zIndex?: number;
  isBanner?: boolean;
};

function CenteredPopupWrapper(props: Props): React.ReactElement {
  return (
    <StyledCenteredPopupWrapper
      padding={props.padding}
      zIndex={props.zIndex}
      isBanner={props.isBanner}
    >
      {props.children}
    </StyledCenteredPopupWrapper>
  );
}

export default CenteredPopupWrapper;
