import React from 'react';
import { StyledBranchDate } from '../styles';
import { IcoClock, IcoDate } from 'src/components/Icon';
import { TBranch } from 'src/types/types';
import { commfyHoliday } from 'src/utils/branch';

type TProps = {
  branch: TBranch;
};

function BranchDate(props: TProps): React.ReactElement {
  const {
    start_hour,
    start_min,
    end_hour,
    end_min,
    holi_dt1,
    holi_dt2,
    holi_dt3,
  } = props.branch;

  return (
    <StyledBranchDate className="date_area">
      {start_hour && (
        <div>
          <p>
            <IcoClock />
          </p>
          <div className="txt">
            운영시간 : {start_hour}:{start_min} ~ {end_hour}:{end_min}
          </div>
        </div>
      )}
      {(holi_dt1 || holi_dt2 || holi_dt3) && (
        <div>
          <p>
            <IcoDate />
          </p>
          <div className="txt">
            휴무일 : {commfyHoliday(holi_dt1, holi_dt2, holi_dt3)}
          </div>
        </div>
      )}
    </StyledBranchDate>
  );
}

export default BranchDate;
