import React, { useEffect, useState } from 'react';
import {
  StyledBorderSelectBox,
  StyledSelectBoxDropDown,
  StyledSelectBoxDropDownV2,
  StyledSelectBoxFilter,
  StyleSelectSnowpPointTargetDropDownProps,
} from './styles';
import { IcoFilter } from '../Icon';
import { Moment } from 'moment';

/**
 * @name 드롭다운 스타일
 * @param props
 * @returns
 */
export type TSelectBoxDropDown = {
  id: string;
  name: string;
};

type SelectBoxDropDownProps = {
  filterList: TSelectBoxDropDown[];
  onChange: (option: TSelectBoxDropDown) => void;
  fontSize?: number;
  margin?: string;
  defaultOption?: TSelectBoxDropDown;
};

export function SelectBoxDropDown(
  props: SelectBoxDropDownProps,
): React.ReactElement {
  const fontSize = props.fontSize || 28;
  const margin = props.margin || '';

  const [textWidth, setTextWidth] = useState<number>(0);
  const [selectValue, setSelectValue] = useState<string>('');

  const handleChange = e => {
    setSelectValue(e.target.value);

    const selectOption = JSON.parse(e.target.value);
    props.onChange && props.onChange(JSON.parse(e.target.value));

    //옵션 변경 시 선택 옵션 명 길이 체크
    const _textWidth = sumTextWidth(selectOption.name);
    if (_textWidth > 0) setTextWidth(_textWidth);
  };

  // 텍스트 너비 구하기
  const sumTextWidth = (selectOption: string) => {
    const hiddenArea = document.getElementById('hidden_area');
    if (hiddenArea) {
      hiddenArea.innerHTML = selectOption;
      return hiddenArea.offsetWidth + 30;
    } else {
      return 0;
    }
  };

  useEffect(() => {
    if (props.filterList.length > 0) {
      const _textWidth = sumTextWidth(props.filterList[0].name);

      if (_textWidth > 0 && !selectValue) {
        //최초 진입 시 첫번째 옵션 명 길이 체크
        setTextWidth(_textWidth);
      }
    }
  }, [props.filterList, selectValue]);

  useEffect(() => {
    if (props.defaultOption) {
      setSelectValue(JSON.stringify(props.defaultOption));
      //옵션 변경 시 선택 옵션 명 길이 체크
      const _textWidth = sumTextWidth(props.defaultOption.name);
      if (_textWidth > 0) setTextWidth(_textWidth);
    }
  }, [props.defaultOption]);

  return (
    <StyledSelectBoxDropDown
      fontSize={fontSize}
      margin={margin}
      textWidth={textWidth}
    >
      <span id="hidden_area"></span>
      <select onChange={handleChange} value={selectValue}>
        {props.filterList.map(filter => {
          return (
            <option value={JSON.stringify(filter)} key={filter.id}>
              {filter.name}
            </option>
          );
        })}
      </select>
    </StyledSelectBoxDropDown>
  );
}

/**
 * @name 필터 스타일
 * @param props
 * @returns
 */
export type TSelectBoxFilter = {
  id: string;
  name: string;
  sort_dvs?: string;
};

type SelectBoxFilterProps = {
  filterList: TSelectBoxFilter[];
  onChange: (option: TSelectBoxFilter) => void;
  seletSort?: TSelectBoxFilter;
};

export function SelectBoxFilter(
  props: SelectBoxFilterProps,
): React.ReactElement {
  const [textWidth, setTextWidth] = useState<number>(0);
  const [selectValue, setSelectValue] = useState<string>('');

  const handleChange = e => {
    setSelectValue(e.target.value);

    const selectOption = JSON.parse(e.target.value);
    props.onChange && props.onChange(JSON.parse(e.target.value));

    //옵션 변경 시 선택 옵션 명 길이 체크
    const _textWidth = sumTextWidth(selectOption.name);
    if (_textWidth > 0) setTextWidth(_textWidth);
  };

  // 텍스트 너비 구하기
  const sumTextWidth = (selectOption: string) => {
    const hiddenArea = document.getElementById('hidden_filter');
    if (hiddenArea) {
      hiddenArea.innerHTML = selectOption;
      return hiddenArea.offsetWidth;
    } else {
      return 0;
    }
  };

  useEffect(() => {
    const _textWidth = sumTextWidth(props.filterList[0].name);

    if (_textWidth > 0) {
      //최초 진입 시 첫번째 옵션 명 길이 체크
      setTextWidth(_textWidth);
      //탭 이동 시 선택 값 초기화
      setSelectValue(JSON.stringify(props.filterList[0]));
    }
  }, [props.filterList]);

  useEffect(() => {
    if (props.seletSort) {
      setSelectValue(JSON.stringify(props.seletSort));
      //옵션 변경 시 선택 옵션 명 길이 체크
      const _textWidth = sumTextWidth(props.seletSort.name);
      if (_textWidth > 0) setTextWidth(_textWidth);
    }
  }, [props.seletSort]);

  return (
    <StyledSelectBoxFilter textWidth={textWidth}>
      <span id="hidden_filter"></span>
      <div>
        <IcoFilter />
      </div>
      <select name="" id="" onChange={handleChange} value={selectValue}>
        {props.filterList.map(filter => {
          return (
            <option value={JSON.stringify(filter)} key={filter.id}>
              {filter.name}
            </option>
          );
        })}
      </select>
    </StyledSelectBoxFilter>
  );
}

/**
 * @name 테두리 드롭다운 스타일
 * @param props
 * @returns
 */
export type TSelectBoxBorderDropDown = {
  cd: string;
  name: string;
};
type SelectBoxBorderDropDownProps = {
  filterList: TSelectBoxBorderDropDown[];
  onChange: (option: TSelectBoxBorderDropDown) => void;
  color?: string;
  disabled?: boolean;
  defaultOption?: TSelectBoxBorderDropDown;
};
export function SelectBoxBorderDropDown(
  props: SelectBoxBorderDropDownProps,
): React.ReactElement {
  const handleChange = e => {
    props.onChange && props.onChange(JSON.parse(e.target.value));
  };
  return (
    <StyledBorderSelectBox textColor={props.color}>
      <select onChange={handleChange} disabled={props.disabled}>
        {/* default 하드코딩 */}
        {props.defaultOption && (
          <option
            key={props.defaultOption.cd}
            value={JSON.stringify(props.defaultOption)}
          >
            {props.defaultOption.name}
          </option>
        )}
        {props.filterList.map((filter, index) => {
          return (
            <option key={index} value={JSON.stringify(filter)}>
              {filter.name}
            </option>
          );
        })}
      </select>
    </StyledBorderSelectBox>
  );
}

/**
 * @name 대상금액드롭다운 스타일
 * @param Props
 * @returns
 */

type SelectSnowpPointTargetDropDownProps = {
  filterList: Moment[];
  onChange: (option: TSelectBoxDropDown) => void;
  fontSize?: number;
  textColor?: string;
};

export function SelectSnowpPointTargetDropDown(
  props: SelectSnowpPointTargetDropDownProps,
): React.ReactElement {
  const fontSize = props.fontSize || 28;

  const textColor = props.textColor || '';

  const handleChange = e => {
    const selectedValue = e.target.value;
    props.onChange && props.onChange(selectedValue);
  };
  return (
    <StyleSelectSnowpPointTargetDropDownProps
      fontSize={fontSize}
      textColor={textColor}
    >
      <select onChange={handleChange}>
        {props.filterList.map((item, i) => {
          return (
            <option key={i} value={item.format('YYYYMM')}>
              {`${item.format('M')}월 스노우플랜 대상금액`}
            </option>
          );
        })}
      </select>
    </StyleSelectSnowpPointTargetDropDownProps>
  );
}

/**
 * @name 드롭다운 스타일
 * @param props
 * @returns
 */
export type TSelectBoxDropDownV2 = {
  id: string;
  name: string;
};

type SelectBoxDropDownPropsV2 = {
  filterList: TSelectBoxDropDown[];
  onChange: (option: TSelectBoxDropDown) => void;
  fontSize?: number;
  textColor?: string;
};

export function SelectBoxDropDownv2(
  props: SelectBoxDropDownPropsV2,
): React.ReactElement {
  const fontSize = props.fontSize || 28;
  const textColor = props.textColor || '';

  const handleChange = e => {
    props.onChange && props.onChange(JSON.parse(e.target.value));
  };

  return (
    <StyledSelectBoxDropDownV2 fontSize={fontSize} textColor={textColor}>
      <select onChange={handleChange}>
        {props.filterList.map(filter => {
          return (
            <option value={JSON.stringify(filter)} key={filter.id}>
              {filter.name}
            </option>
          );
        })}
      </select>
    </StyledSelectBoxDropDownV2>
  );
}

export type TSelectBoxBorderDropDownV3 = {
  str_cd: string;
  str_nm: string;
};
type SelectBoxBorderDropDownPropsV3 = {
  filterList: TSelectBoxBorderDropDownV3[];
  onChange: (option: TSelectBoxBorderDropDownV3) => void;
  color?: string;
  disabled?: boolean;
};
export function SelectBoxBorderDropDownV3(
  props: SelectBoxBorderDropDownPropsV3,
): React.ReactElement {
  const handleChange = e => {
    props.onChange && props.onChange(JSON.parse(e.target.value));
  };
  return (
    <StyledBorderSelectBox textColor={props.color}>
      <select onChange={handleChange} disabled={props.disabled}>
        {props.filterList.map(filter => {
          return (
            <option key={filter.str_cd} value={JSON.stringify(filter)}>
              {filter.str_nm}
            </option>
          );
        })}
      </select>
    </StyledBorderSelectBox>
  );
}
