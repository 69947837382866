import { LoginAction } from '../actions/loginActions';
import ActionTypes from '../constants/loginConstants';
import { TLoginStatus } from '../types/loginTypes';
import { E_INTRO_LOGIN_STATE } from 'src/utils/enum';

export const initialState = {
  loginStatus: E_INTRO_LOGIN_STATE.INIT,
};

function AppReducer(
  state: TLoginStatus = initialState,
  action: LoginAction,
): TLoginStatus {
  switch (action.type) {
    case ActionTypes.SET_AUTOLOGIN_STATUS:
      return {
        ...state,
        loginStatus: action.payload.loginStatus,
      };

    default:
      return state;
  }
}

export default AppReducer;
