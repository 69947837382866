import { thejamsilFont } from 'src/styles/font';
import styled, { css } from 'styled-components';
import { rem } from 'src/styles/function';
import { ICON_PATH } from 'src/utils/constants';

/**
 * @name 드롭다운 카테고리 선택 컴포넌트 (드롭다운)
 * @param fontSize 폰트 크기
 */
export const StyledSelectBoxDropDown = styled.div<{
  fontSize: number;
  margin: string;
  textWidth: number;
}>`
  margin: ${props => (props.margin ? props.margin : `0 0 0 ${rem(20)}`)};
  select {
    ${thejamsilFont('medium')}
    width: ${props => rem(props.textWidth)};
    font-size: ${props => rem(props.fontSize)};
    background: url(${ICON_PATH}/icon_dropdown.png) no-repeat 97% 50% /
      ${rem(24)} auto;
  }
  #hidden_area {
    ${thejamsilFont('medium')}
    visibility: hidden;
    position: absolute;
    z-index: -999;
    font-size: ${props => rem(props.fontSize)};
  }
`;

/**
 * @name 드롭다운 카테고리 선택 컴포넌트 (드롭다운)
 * @param fontSize 폰트 크기
 */
export const StyledSelectBoxDropDownV2 = styled.div<{
  fontSize: number;
  textColor?: string;
}>`
  margin: 0;
  color: ${props => props.color};

  select {
    width: 100%;
    ${thejamsilFont('medium')}
    font-size: ${props => rem(props.fontSize)};
    background: url('${ICON_PATH}/icon_dropdown.png') no-repeat 0 0;
    background-size: ${rem(24)} ${rem(24)};
    background-position: calc(100%);
    ${props => {
      switch (props.textColor) {
        case 'white':
          return css`
            ${thejamsilFont('medium')}
            color: ${({ theme }) => theme.colors.white};
          `;
        default:
          return css`
            ${thejamsilFont('medium')};
            color: black;
          `;
      }
    }}
  }
`;

/**
 * @name 드롭다운 카테고리 선택 컴포넌트 (드롭다운)
 * @param fontSize 폰트 크기
 */
export const StyleSelectSnowpPointTargetDropDownProps = styled.div<{
  fontSize: number;
  textColor?: string;
}>`
  margin: 0;
  color: ${props => props.textColor};

  select {
    width: 100%;
    ${thejamsilFont('medium')}
    font-size: ${props => rem(props.fontSize)};
    background: url('${ICON_PATH}/icon_dropdown_white.png') no-repeat 0 0;
    background-size: ${rem(24)} ${rem(24)};
    background-position: calc(101%);
    color: white;
    border: none;
    border-bottom: 2px solid white;
    white-space: nowrap;
    text-overflow: ellipsis;
  }

  option {
    color: black;
  }
`;

/**
 * @name 필터 아이콘 컴포넌트 (아이콘)
 */
export const StyledSelectBoxFilter = styled.div<{ textWidth: number }>`
  ${thejamsilFont('regular')}
  text-align: right;
  #hidden_filter {
    visibility: hidden;
    position: absolute;
    z-index: -999;
    ${thejamsilFont('regular')}
    font-size: ${rem(14)};
  }
  select {
    ${thejamsilFont('regular')}
    font-size: ${rem(14)};
    width: ${props => rem(props.textWidth)};
  }
  div {
    display: inline-block;
    width: ${rem(15)};
    height: ${rem(15)};
    vertical-align: middle;
    margin-right: ${rem(5)};
  }
`;

/**
 * @name 테두리 드롭다운 컴포넌트
 */
export const StyledBorderSelectBox = styled.section<{ textColor?: string }>`
  margin-bottom: ${rem(10)};
  padding: ${rem(18)} ${rem(18)} ${rem(18)} ${rem(15)};
  border: 1px solid ${({ theme }) => theme.colors.gray01};
  border-radius: ${rem(6)};
  box-sizing: border-box;
  select {
    font-size: ${rem(14)};
    width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
    padding-right: ${rem(35)};
    background: url('${ICON_PATH}/icon_dropdown.png') no-repeat 0 0;
    background-size: ${rem(24)} ${rem(24)};
    background-position: calc(100%);
  }
  ${props => {
    switch (props.textColor) {
      case 'light':
        return css`
          select {
            ${thejamsilFont('light')};
            color: ${({ theme }) => theme.colors.black};
          }
        `;
      case 'shadow':
        return css`
          background-color: ${({ theme }) => theme.colors.gray_bg};
          border-color: #e5e5e5;
          select {
            ${thejamsilFont('light')};
            color: ${({ theme }) => theme.colors.gray01};
            background-image: url('${ICON_PATH}/icon_dropdown_gray.png');
          }
        `;
      default:
        return css`
          select {
            ${thejamsilFont('medium')};
            color: black;
          }
        `;
    }
  }}
`;
