import { E_POPUP_STATE } from 'src/utils/enum';
import ActionTypes from '../constants/mainConstants';

import { E_MAIN_MENU_STATE, MainAction, TMainState } from '../types/mainTypes';

export const initialState: TMainState = {
  mainMenuState: E_MAIN_MENU_STATE.NONE,
  isOpenMartSearchPopup: E_POPUP_STATE.NONE,
};

function mainReducer(
  state: TMainState = initialState,
  action: MainAction,
): TMainState {
  switch (action.type) {
    case ActionTypes.OPEN_MAIN_MENU:
      return {
        ...state,
        mainMenuState: E_MAIN_MENU_STATE.OPEN,
      };
    case ActionTypes.CLOSE_MAIN_MENU:
      return {
        ...state,
        mainMenuState: E_MAIN_MENU_STATE.CLOSE,
      };
    case ActionTypes.NONE_MAIN_MENU:
      return {
        ...state,
        mainMenuState: E_MAIN_MENU_STATE.NONE,
      };
    case ActionTypes.OPEN_MART_SEARCH_POPUP:
      return {
        ...state,
        isOpenMartSearchPopup: E_POPUP_STATE.OPEN,
      };
    case ActionTypes.CLOSE_MART_SEARCH_POPUP:
      return {
        ...state,
        isOpenMartSearchPopup: E_POPUP_STATE.CLOSE,
      };
    default:
      return state;
  }
}

export default mainReducer;
