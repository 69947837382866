import { createGlobalStyle } from 'styled-components';
import reset from 'styled-reset';
import { font, thejamsilFont } from './font';
import { rem } from './function';

const GlobalStyles = createGlobalStyle`
/* css 리셋 */
  ${reset}
/* font */
  ${font}
  body {
    ${thejamsilFont('light')}
    
  }
  ::-webkit-scrollbar {
    display: none;
  }
  a{
      text-decoration: none;
      color:inherit;
  }
  button{
      border: none;
      background: none;
      padding: 0;
      outline: none;
      cursor: pointer;
      color: black;
  }
  select {
    margin: 0;
    border: none;
    padding: 0;
    font-size: 1rem;
    vertical-align: middle;
    color: #000;
    outline: 0;
    -webkit-appearance:none; 
    -moz-appearance:none; 
    appearance:none;
  }
  input,
  select,
  textarea {
    border-radius: 0;
    -webkit-border-radius: 0;
    background-color: #fff;
  }
  img,
  input {
    vertical-align: top;
    border: none;
    outline: none;
  }
  body,
  div,
  span,
  applet,
  object,
  iframe,
  h1,
  h2,
  h3,
  h4,
  h5,
  h6,
  p,
  blockquote,
  pre,
  abbr,
  acronym,
  address,
  big,
  cite,
  code,
  del,
  dfn,
  em,
  font,
  img,
  ins,
  kbd,
  q,
  s,
  samp,
  small,
  strike,
  strong,
  sub,
  sup,
  tt,
  var,
  b,
  u,
  i,
  center,
  dl,
  dt,
  dd,
  ol,
  ul,
  li,
  fieldset,
  form,
  label,
  legend,
  table,
  caption,
  tbody,
  tfoot,
  thead,
  tr,
  th,
  td {
    vertical-align: baseline;
    background: transparent;
    -webkit-text-size-adjust: none;
    list-style: none;
    line-height: 1;
    -webkit-tap-highlight-color: transparent;
  }
  input:-webkit-autofill,
  input:-webkit-autofill:hover,
  input:-webkit-autofill:focus,
  input:-webkit-autofill:active {
    transition: background-color 5000s ease-in-out 0s;
    -webkit-transition: background-color 9999s ease-out;
    -webkit-box-shadow: 0 0 0px 1000px white inset !important;
    -webkit-text-fill-color: #000 !important;
}


/* 스와이프 기본 css */
.swiper {
  margin-left: auto;
  margin-right: auto;
  list-style: none;
  padding: 0;
}
.swiper-wrapper {
  position: relative;
  width: 100%;
  height: 100%;
  z-index: 1;
  display: flex;
  transition-property: transform;
  box-sizing: content-box;
}
.swiper-wrapper-chip {
  position: relative;
  width: 100%;
  height: 100%;
  z-index: 1;
  display: flex;
  transition-property: transform;
  box-sizing: content-box;
}
.blind {
  position: absolute;
  clip:rect(0 0 0 0) ;
  width: 1px;
  height: 1px;
  margin: -1px;
  overflow: hidden;
}
.ad_area {
  margin-top: ${rem(10)};
  position: absolute;
  width: 98%;
  text-align: right;
  z-index: 50;
  .ad_icon {
    img {
      width: ${rem(35)};
      vertical-align: bottom;
    }
  }
}
`;

export default GlobalStyles;
