import React from 'react';

import { StyledSideMenuList } from '../styles';
import { bridgeOpeBottleBunkerApp, bridgeOpenBrowser } from 'src/utils/bridge';
import { gaEvent } from 'src/utils/ga';
import useSelectorEqual from 'src/hooks/useSelectorEqual';
import { RootState } from 'src/store/reducers';

function SideMenuBrandSection(): React.ReactElement {
  const [mainStoreState] = useSelectorEqual((state: RootState) => [
    state.user.mainStoreState,
  ]);

  const handleClickLotteOn = () => {
    setGaEvent('롯데마트몰');
    bridgeOpenBrowser(
      `https://www.lotteon.com/p/display/main/lottemart?mall_no=4&`,
    );
  };
  const handleClickBottle = () => {
    setGaEvent('보틀벙커');
    bridgeOpeBottleBunkerApp();
  };

  const handleClickToy = () => {
    setGaEvent('토이저러스');
    bridgeOpenBrowser(
      `https://www.lotteon.com/p/display/main/toysrus?mall_no9&ch_no=100195&ch_dtl_no=1000617`,
    );
  };

  const setGaEvent = title => {
    if (mainStoreState.main_str_type_nm)
      gaEvent(
        `${title} 버튼`,
        mainStoreState.main_str_type_nm,
        '',
        '전체메뉴 팝업',
      );
  };
  return (
    <StyledSideMenuList>
      {mainStoreState.str_cd && (
        <ul>
          <span>롯데마트 브랜드</span>
          <li onClick={handleClickLotteOn}>롯데마트몰</li>
          <li onClick={handleClickBottle}>보틀벙커</li>
          <li onClick={handleClickToy}>토이저러스</li>
        </ul>
      )}
      <div className="base_line" />
    </StyledSideMenuList>
  );
}

export default SideMenuBrandSection;
