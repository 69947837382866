import React from 'react';
import { StyledBranchAddress } from '../styles';

type TProps = {
  children?: React.ReactNode;
};

function BranchAddress(props: TProps): React.ReactElement {
  return <StyledBranchAddress>{props.children}</StyledBranchAddress>;
}

export default BranchAddress;
