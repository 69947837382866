import React, { useEffect } from 'react';
import Dim from '../Dim';
import CenteredPopupWrapper from './components/CenteredPopupWrapper';
import { E_POPUP_STATE } from 'src/utils/enum';
import { Button, ButtonGroup } from '../Button';
import { useDispatch } from 'react-redux';
import { actionOpenMartSearchPopup } from 'src/redux/actions/mainActions';
import { useMutation } from '@tanstack/react-query';
import { apiGetMainStore, apiSetChangeMainStore } from 'src/api/apiUser';
import { actionCloseMartSettingPopup } from 'src/redux/actions/appActions';
import { actionSetMainStoreState } from 'src/redux/actions/userActions';
import { Image } from '../Image';
import { IMAGE_ROOT } from 'src/utils/constants';
import { StyledMartSettingPopupWraaper } from './styles';
import { gaEvent, gaPageView } from 'src/utils/ga';

type TProps = {
  isOpen: E_POPUP_STATE;
};

function MartSettingPopup(prpos: TProps): React.ReactElement {
  const dispatch = useDispatch();
  const pageName = '[미설정]주이용점포 선택하기 팝업';

  // 주이용점포 조회
  const getMainStore = useMutation(apiGetMainStore, {
    onSuccess: data => {
      dispatch(actionSetMainStoreState(data));
    },
  });
  // 주이용점포 변경
  const setMainStore = useMutation(apiSetChangeMainStore, {
    onSuccess: () => {
      getMainStore.mutate();
    },
  });

  // 취소 버튼 클릭
  const handleClickPopupCancel = () => {
    // 주이용점포 설정 팝업 닫기
    dispatch(actionCloseMartSettingPopup());
    // 주이용점포 강변점 설정
    setMainStore.mutate('301');
  };

  // 점포 선택하기 버튼 클릭
  const handleClickSelectBranchBtn = () => {
    gaEvent('점포 선택하기', '', '', pageName);
    // 주이용점포 검색 팝업 열기
    dispatch(actionOpenMartSearchPopup());
    // 주이용점포 설정 팝업 닫기
    dispatch(actionCloseMartSettingPopup());
  };

  useEffect(() => {
    gaPageView(location.pathname, '', pageName);
  }, []);

  return (
    <>
      <Dim zIndex={609} isDisplay={prpos.isOpen === E_POPUP_STATE.OPEN} />
      <CenteredPopupWrapper padding="0" zIndex={610}>
        <StyledMartSettingPopupWraaper>
          <Image
            src={`${IMAGE_ROOT}/default/no_mainstore_notice_img.jpg`}
            alt="주 이용 점포를 선택해주세요. 미 설정 시 주이용 점포는 강변점으로 설정됩니다."
          />
          <div className="btn_area">
            <ButtonGroup count={2}>
              <Button
                text={'닫기'}
                buttonColor={'black'}
                variant={'outlined'}
                onClick={handleClickPopupCancel}
              />
              <Button
                text={'점포 선택하기'}
                buttonColor={'red'}
                variant={'contained'}
                onClick={handleClickSelectBranchBtn}
              />
            </ButtonGroup>
          </div>
        </StyledMartSettingPopupWraaper>
      </CenteredPopupWrapper>
    </>
  );
}

export default MartSettingPopup;
