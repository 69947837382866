/**
 * @name 서브헤더 제목
 * @param pathname 페이지 경로
 * @param subTitle 서브헤더 제목
 * @param pageName GA 페이지 명
 */
export const PAGE_PATH_LIST = [
  {
    pathname: '/',
    subTitle: ``,
    pageName: '메인',
  },
  {
    pathname: '/scan/martsearch',
    subTitle: 'SCAN&GO 점포선택',
    pageName: 'SCAN&GO 점포선택',
  },
  {
    pathname: '/scan/scangohelp',
    subTitle: '',
    pageName: 'SCAN&GO 도움말',
  },
  {
    pathname: '/scan/scangopayment',
    subTitle: 'SCAN&GO',
    pageName: 'SCAN&GO 결제페이지',
  },
  {
    pathname: '/scan/complete',
    subTitle: 'SCAN&GO 완료',
    pageName: 'SCAN&GO 완료',
  },
  {
    pathname: '/scan/productdetail',
    subTitle: '상품상세',
    pageName: '', //SCAN&GO 상품상세
  },
  {
    pathname: '/scango/history',
    subTitle: 'SCAN&GO결제내역',
    pageName: 'SCAN&GO결제내역 상세',
  },
  {
    pathname: '/scango/historycancel',
    subTitle: 'SCAN&GO취소내역',
    pageName: 'SCAN&GO취소내역 상세',
  },
  {
    pathname: '/scango/historylist',
    subTitle: 'SCAN&GO결제내역',
    pageName: 'SCAN&GO결제내역 목록',
  },
  {
    pathname: '/snowpoint/save',
    subTitle: '스노우플랜 이용내역',
    pageName: '스노우플랜 이용내역(적립)',
  },
  {
    pathname: '/snowpoint/use',
    subTitle: '스노우플랜 이용내역',
    pageName: '스노우플랜 이용내역(사용/소멸)',
  },
  {
    pathname: '/snowpoint/expire',
    subTitle: '스노우플랜 이용내역',
    pageName: '스노우플랜 이용내역(소멸예정)',
  },
  {
    pathname: '/snowpoint/targethistory',
    subTitle: '스노우플랜 대상금액',
    pageName: '스노우플랜 대상금액',
  },
  {
    pathname: '/snowpoint/snowplannotice',
    subTitle: '이벤트',
    pageName: '스노우플랜이란',
  },
  {
    pathname: '/eventlist/ing',
    subTitle: '이벤트',
    pageName: '이벤트 목록(진행중)',
  },
  {
    pathname: '/eventlist/end',
    subTitle: '이벤트',
    pageName: '이벤트 목록(종료)',
  },
  {
    pathname: '/receipt/list',
    subTitle: '스마트영수증', //'<span>스마트</span>영수증',
    pageName: '스마트영수증 목록',
  },
  {
    pathname: '/recommendproduct/mdpick',
    subTitle: '추천상품',
    pageName: '추천상품 목록(mdpick)',
  },
  {
    pathname: '/recommendproduct/new',
    subTitle: '추천상품',
    pageName: '추천상품 목록(신상품)',
  },
  {
    pathname: '/recommendproduct/best',
    subTitle: '추천상품',
    pageName: '추천상품 목록(인기상품)',
  },
  {
    pathname: '/recommendproduct/like',
    subTitle: '추천상품',
    pageName: '추천상품 목록(관심상품)',
  },
  {
    pathname: '/productdetail',
    subTitle: '상품상세',
    pageName: '', //상품 상세
  },
  {
    pathname: '/alarm/alarm',
    subTitle: '알림',
    pageName: '알림(알림)',
  },
  {
    pathname: '/alarm/notice',
    subTitle: '알림',
    pageName: '알림(공지사항)',
  },
  {
    pathname: '/point',
    subTitle: '포인트',
    pageName: '포인트',
  },
  {
    pathname: '/point/benefit',
    subTitle: '혜택 이용하기',
    pageName: '', // 혜택이용하기(탭명)
  },
  {
    pathname: '/mybenefit/coupon',
    subTitle: 'MY 혜택',
    pageName: 'MY 혜택(나만의 쿠폰)',
  },
  {
    pathname: '/mybenefit/discount',
    subTitle: 'MY 혜택',
    pageName: 'MY 혜택(회원할인)',
  },
  {
    pathname: '/faq',
    subTitle: '자주 묻는 질문',
    pageName: '자주 묻는 질문',
  },
  {
    pathname: '/martdetail',
    subTitle: '매장찾기',
    pageName: '', //매장찾기(점포정보)
  },
  {
    pathname: '/setting',
    subTitle: '설정',
    pageName: '설정',
  },
  {
    pathname: '/setting/social',
    subTitle: 'SNS 계정 연동',
    pageName: 'SNS 계정 연동',
  },
  {
    pathname: '/setting/version',
    subTitle: '버전정보',
    pageName: '버전정보',
  },
  {
    pathname: '/leaflet',
    subTitle: '전단',
    pageName: '전단',
  },
  {
    pathname: '/leaflet/news',
    subTitle: '점포 소식 보기',
    pageName: '점포 소식 보기',
  },
  {
    pathname: '/event/apply',
    subTitle: '이벤트',
    pageName: '', //응모 이벤트
  },
  {
    pathname: '/event/apply/win',
    subTitle: '이벤트',
    pageName: '', //응모 당첨안내 이벤트
  },
  {
    pathname: '/event/compete',
    subTitle: '이벤트',
    pageName: '', //달려라선착순 이벤트
  },
  {
    pathname: '/event/coupon',
    subTitle: '이벤트',
    pageName: '', //쿠폰 다운로드 이벤트
  },
  {
    pathname: '/event/landing',
    subTitle: '이벤트',
    pageName: '', //랜딩 이벤트
  },
  {
    pathname: '/event/poll',
    subTitle: '이벤트',
    pageName: '', //설문 이벤트
  },
  {
    pathname: '/event/stamp',
    subTitle: '이벤트',
    pageName: '', //스탬프 이벤트
  },
  {
    pathname: '/event/accrue',
    subTitle: '이벤트',
    pageName: '', //누적 이벤트
  },
  {
    pathname: '/event/hidden',
    subTitle: '이벤트',
    pageName: '', //히든 이벤트
  },
  {
    pathname: '/tenant/list',
    subTitle: '입점매장',
    pageName: '입점매장 리스트',
  },
  {
    pathname: '/login',
    subTitle: '',
    pageName: '로그인 목록',
  },
  {
    pathname: '/login/lpoint',
    subTitle: '',
    pageName: 'L.POINT 로그인',
  },
  {
    pathname: '/login/lpoint/info',
    subTitle: '',
    pageName: 'L.POINT 로그인 안내',
  },
  {
    pathname: '/signup',
    subTitle: '',
    pageName: '회원가입 약관동의',
  },
  {
    pathname: '/setting/withdraw',
    subTitle: '서비스탈퇴',
    pageName: '서비스탈퇴',
  },
  {
    pathname: '/setting/withdraw/complete',
    subTitle: '서비스탈퇴',
    pageName: '서비스탈퇴 완료',
  },
  {
    pathname: '/tenant/detail',
    subTitle: `입점 매장`,
    pageName: '', //입점매장 상세
  },
  {
    pathname: '/kid',
    subTitle: '우리아이M혜택',
    pageName: '우리아이M혜택 메인',
  },
  {
    pathname: '/kid/agree',
    subTitle: '우리아이M혜택',
    pageName: '우리아이M혜택 가입',
  },
  {
    pathname: '/kid/contentdetail',
    subTitle: '우리아이M혜택',
    pageName: '', //우리아이M혜택 상세
  },
  {
    pathname: '/kid/withdrawal',
    subTitle: '우리아이M혜택',
    pageName: '우리아이M혜택 탈퇴',
  },
  {
    pathname: '/tenant/favorite',
    subTitle: `찜한 입점매장`,
    pageName: '찜한 입점매장 리스트',
  },
  {
    pathname: '/system/authorization',
    subTitle: ``,
    pageName: '접근 권한 안내',
  },
  {
    pathname: '/system/inspection',
    subTitle: ``,
    pageName: '시스템 점검 안내',
  },
  {
    pathname: '/system/update',
    subTitle: ``,
    pageName: '시스템 필수업데이트 안내',
  },
  {
    pathname: '/system/launcher',
    subTitle: ``,
    pageName: '시스템 앱 다운로드 안내',
  },
  {
    pathname: '/authemployee',
    subTitle: '임직원 인증',
    pageName: '임직원 인증',
  },
  {
    pathname: '/authemployee/confirm',
    subTitle: '임직원 인증',
    pageName: '임직원 인증번호 확인',
  },
  {
    pathname: '/authemployee/success',
    subTitle: '임직원 인증',
    pageName: '임직원 인증 완료',
  },
  {
    pathname: '/qna',
    subTitle: '건의내역 확인',
    pageName: '1:1 건의내역 확인',
  },
  {
    pathname: '/qna/detail',
    subTitle: '1:1 건의내역 상세',
    pageName: '1:1 건의내역 상세',
  },
  {
    pathname: '/qna/register',
    subTitle: '1:1 건의',
    pageName: '1:1 건의 등록',
  },
  {
    pathname: '/mybenefit/coupondetail',
    subTitle: 'MY혜택',
    pageName: '', //MY혜택 교환 쿠폰 상세
  },
  {
    pathname: '/super/coupondetail',
    subTitle: 'MY혜택',
    pageName: '', //MY혜택 슈퍼 교환 쿠폰 상세
  },
  {
    pathname: '/super/recommendproduct',
    subTitle: '추천상품',
    pageName: '슈퍼 추천상품 목록',
  },
  {
    pathname: '/super/event',
    subTitle: '이벤트',
    pageName: '', //슈퍼 하드코딩 이벤트
  },
  {
    pathname: '/super/grade/info',
    subTitle: '이벤트',
    pageName: '슈퍼 등급혜택 안내',
  },
];
