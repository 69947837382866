import { combineReducers } from 'redux';

import main from '../redux/reducers/mainReducer';
import app from '../redux/reducers/appReducer';
import login from '../redux/reducers/loginReducer';
import tenant from '../redux/reducers/tenantReducer';
import mybenefit from '../redux/reducers/myBenefitReducer';
import receipt from '../redux/reducers/receiptReducer';
import snowpoint from '../redux/reducers/snowPointReducer';
import user from '../redux/reducers/userReducer';

const rootReducer = combineReducers({
  main,
  app,
  tenant,
  login,
  mybenefit,
  receipt,
  snowpoint,
  user,
});

export default rootReducer;

export type RootState = ReturnType<typeof rootReducer>;
