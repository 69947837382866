import api from 'src/utils/api';
import { E_API_SERVICEID } from 'src/utils/enum';

const gatewayGubun = {
  'x-ssp-gateway-service-id': E_API_SERVICEID.EVENT,
};

/**
 * @name 이벤트 배너 조회
 */
export const apiGetBannerInfo = async bannerParam => {
  const response = await api.post(
    '/fo/event/eventbanner/list',
    {
      ...bannerParam,
    },
    {
      headers: {
        ...gatewayGubun,
      },
    },
  );

  const data = await response?.data;
  return data;
};

/**
 * @name 응모 상세 조회
 */
export const apiPostApplyDetail = async event_id => {
  const response = await api.post(
    '/fo/event/apply/select',
    {
      apply_id: event_id,
    },
    {
      headers: {
        ...gatewayGubun,
      },
    },
  );

  const data = await response?.data;
  return data;
};

/**
 * @name 응모 참여
 */
export const apiPostApplyJoin = async params => {
  const response = await api.post(
    '/fo/event/apply/ptc',
    {
      ...params,
    },
    {
      headers: {
        ...gatewayGubun,
      },
    },
  );

  const data = await response?.data;
  return data;
};

/**
 * @name 이벤트 랜딩 조회
 */
export const apiGetLandingDetail = async event_id => {
  const response = await api.post(
    '/fo/event/landing/select',
    {
      banner_id: event_id,
    },
    {
      headers: {
        ...gatewayGubun,
      },
    },
  );

  return response;
};

/**
 * @name 이벤트 스탬프 조회
 */
export const apiGetStampList = async event_id => {
  const response = await api.post(
    '/fo/event/stamp/select',
    {
      stamp_id: event_id,
    },
    {
      headers: {
        ...gatewayGubun,
      },
    },
  );

  const data = await response?.data;
  return data;
};

/**
 * @name 이벤트 스탬프 보상수령
 */
export const apiPostReward = async params => {
  const response = await api.post(
    '/fo/event/stamp/getreward',
    {
      ...params,
    },
    {
      headers: {
        ...gatewayGubun,
      },
    },
  );

  const data = await response?.data;
  return data;
};

/**
 * @name 이벤트 스탬프 출석체크
 */
export const apiPostStampAttend = async params => {
  const response = await api.post(
    '/fo/event/stamp/attend',
    {
      ...params,
    },
    {
      headers: {
        ...gatewayGubun,
      },
    },
  );

  const data = await response?.data;
  return data;
};

/**
 * @name 이벤트 스탬프 참여
 */
export const apiPostStamp = async params => {
  const response = await api.post(
    '/fo/event/stamp/ptc',
    {
      ...params,
    },
    {
      headers: {
        ...gatewayGubun,
      },
    },
  );

  const data = await response?.data;
  return data;
};

/**
 * @name 이벤트 누적 참여
 */
export const apiPostAccrue = async (acc_id: string) => {
  const response = await api.post(
    '/fo/event/acc/ptc',
    {
      acc_id: acc_id,
    },
    {
      headers: {
        ...gatewayGubun,
      },
    },
  );

  const data = await response?.data;
  return data;
};

/**
 * @name 이벤트 누적 금액 조회
 */
export const apiPostAccrueDetail = async event_id => {
  const response = await api.post(
    '/fo/event/acc/select',
    {
      acc_id: event_id,
    },
    {
      headers: {
        ...gatewayGubun,
      },
    },
  );

  const data = await response?.data;
  return data;
};

/**
 * @name 이벤트 누적 참여 달성 쿠폰받기
 */
export const apiPostAccrueCoupon = async params => {
  const response = await api.post(
    '/fo/event/acc/getreward',
    {
      ...params,
    },
    {
      headers: {
        ...gatewayGubun,
      },
    },
  );

  const data = await response?.data;
  return data;
};

/**
 * @name 이벤트 쿠폰리스트 조회
 */
export const apiPostCouponDetail = async event_id => {
  const response = await api.post(
    '/fo/event/cpndwn/select',
    {
      cpn_dwn_id: event_id,
    },
    {
      headers: {
        ...gatewayGubun,
      },
    },
  );

  const data = await response?.data;
  return data;
};

/**
 * @name 이벤트 쿠폰 다운로드
 */
export const apiPostGetCoupon = async params => {
  const response = await api.post(
    '/fo/event/cpndwn/getreward',
    {
      ...params,
    },
    {
      headers: {
        ...gatewayGubun,
      },
    },
  );

  const data = await response?.data;
  return data;
};

/**
 * @name 이벤트 쿠폰 모두 다운로드
 */
export const apiPostGetCouponAll = async params => {
  const response = await api.post(
    '/fo/event/cpndwn/getreward/all',
    {
      ...params,
    },
    {
      headers: {
        ...gatewayGubun,
      },
    },
  );

  const data = await response?.data;
  return data;
};

/**
 * @name 선착순 이벤트 상세 조회
 */
export const apiPostCompeteDetail = async event_id => {
  const response = await api.post(
    '/fo/event/fcfs/select',
    {
      fcfs_id: event_id,
    },
    {
      headers: {
        ...gatewayGubun,
      },
    },
  );

  const data = await response?.data;
  return data;
};

/**
 * @name 선착순 쿠폰 다운로드
 */
export const apiPostCompeteCoupon = async params => {
  try {
    const response = await api.post(
      '/fo/event/fcfs/getreward',
      {
        ...params,
      },
      {
        headers: {
          ...gatewayGubun,
        },
      },
    );

    const data = await response?.data;
    return data;
  } catch (err) {
    console.log(err);
  }
};

/**
 * @name 설문 약관동의
 */
export const apiPostPollTerms = async poll_issue_id => {
  const response = await api.post(
    '/fo/event/poll/agmt/agree',
    {
      poll_issue_id: poll_issue_id,
      agmt_yn: 'Y',
    },
    {
      headers: {
        ...gatewayGubun,
      },
    },
  );

  const data = await response?.data;
  return data;
};

/**
 * @name 설문 상세 조회
 */
export const apiPostPollDetail = async event_id => {
  const response = await api.post(
    '/fo/event/poll/select',
    {
      poll_issue_id: event_id,
    },
    {
      headers: {
        ...gatewayGubun,
      },
    },
  );

  const data = await response?.data;
  return data;
};

/**
 * @name 설문 문항조회
 */
export const apiPostPollList = async pollParam => {
  const response = await api.post(
    '/fo/event/poll/quest/select',
    {
      ...pollParam,
    },
    {
      headers: {
        ...gatewayGubun,
      },
    },
  );

  const data = await response?.data;
  return data;
};

/**
 * @name 설문 답변
 */
export const apiPostPollAnswer = async basicCount => {
  const response = await api.post('/fo/event/poll/quest/answer', basicCount, {
    headers: {
      ...gatewayGubun,
    },
  });

  const data = await response?.data;
  return data;
};

/**
 * @name 배너 조회
 * @param bannerType 1: 메인/2: 전단/3: 스캔앤고
 */
export const apiGetBanner = async params => {
  const response = await api.post(
    '/fo/app/banner/list',
    {
      banner_type: params[1],
      str_cd: params[2],
    },
    {
      headers: {
        ...gatewayGubun,
      },
    },
  );
  const data = await response.data.list;

  return data;
};

/**
 * @name 메인 팝업 조회
 * @param str_type 점포타입
 * @param popup_type 팝업타입('':전체 ,1: 메인, 2: 이벤트, 3: 클럽, 4: 설문)
 */
export const apiGetMainPopup = async params => {
  const response = await api.post(
    '/fo/event/main/popup/main/list',
    {
      ...params,
    },
    {
      headers: { 'x-ssp-gateway-service-id': E_API_SERVICEID.EVENT },
    },
  );

  const data = await response.data;

  return data;
};

/**
 * @name 스플래시 조회
 */
export const apiGetSplash = async () => {
  const response = await api.post(
    '/fo/event/main/popup/splash',
    {},
    {
      headers: { 'x-ssp-gateway-service-id': E_API_SERVICEID.EVENT },
    },
  );

  const data = response.data;

  return data;
};

/**
 * @name 시스템팝업 조회
 * @param sys_popup_type 1:점검 2:업데이트
 */
export const apiGetSysPopupList = async (sys_popup_type: string) => {
  const response = await api.post(
    '/fo/event/main/popup/sys/list',
    { sys_popup_type: sys_popup_type },
    {
      headers: { 'x-ssp-gateway-service-id': E_API_SERVICEID.EVENT },
    },
  );
  const data = await response.data;

  return data;
};

/**
 * @name 공지사항 목록 조회
 */
export const apiGetNoticeList = async params => {
  const response = await api.post(
    '/fo/event/main/notice/list',
    {
      ...params,
    },
    {
      headers: { 'x-ssp-gateway-service-id': E_API_SERVICEID.EVENT },
    },
  );
  const data = await response.data;

  return data;
};

/**
 * @name FAQ 목록 조회
 */
export const apiGetFaqList = async params => {
  const response = await api.post(
    '/fo/event/main/faq/list',
    {
      ...params,
    },
    {
      headers: { 'x-ssp-gateway-service-id': E_API_SERVICEID.EVENT },
    },
  );
  const data = await response.data;

  return data;
};

/**
 * @name 진행중인 룰렛 이벤트 조회
 */
export const apiGetProceedingRouletteEvent = async params => {
  const response = await api.post(
    '/fo/event/roulette/proceeding',
    {
      ...params,
    },
    {
      headers: {
        ...gatewayGubun,
      },
    },
  );

  const data = response.data;

  return data;
};

/**
 * @name 룰렛이벤트정보 조회
 */
export const apiGetRouletteEvent = async params => {
  const response = await api.post(
    '/fo/event/roulette/show',
    {
      ...params,
    },
    {
      headers: {
        ...gatewayGubun,
      },
    },
  );

  const data = await response.data;

  return data;
};

/**
 * @name 룰렛이벤트 참여(마트)
 */
export const apiPtcRouletteEventMart = async params => {
  const response = await api.post(
    '/fo/event/roulette/mart/ptc',
    {
      ...params,
    },
    {
      headers: {
        ...gatewayGubun,
      },
    },
  );

  const data = await response.data;

  return data;
};

/**
 * @name 룰렛이벤트 참여(슈퍼)
 */
export const apiPtcRouletteEventSuper = async params => {
  const response = await api.post(
    '/fo/event/roulette/super/ptc',
    {
      ...params,
    },
    {
      headers: {
        ...gatewayGubun,
      },
    },
  );

  const data = await response.data;

  return data;
};

/**
 * @name 슈퍼 룰렛이벤트 직원 확인
 */
export const apiConfirmEmployee = async params => {
  const response = await api.post(
    '/fo/event/roulette/super/ptc/confirm',
    {
      ...params,
    },
    {
      headers: {
        ...gatewayGubun,
      },
    },
  );

  const data = await response.data;

  return data;
};
