import { thejamsilFont } from 'src/styles/font';
import styled from 'styled-components';
import { rem } from 'src/styles/function';

/**
 * @name 주 이용 점포 팝업 스타일 컴포넌트
 */
export const StyledMartSearchPopup = styled.div`
  .sub_title {
    ${thejamsilFont('medium')}
    font-size: ${rem(15)};
    margin-bottom: ${rem(10)};
  }
  .select_body {
    margin: ${rem(80)} ${rem(20)} ${rem(30)} ${rem(20)};
    .popup_sub {
      margin-bottom: ${rem(30)};
    }
  }
`;

/**
 * @name 매장선택 스타일 컴포넌트
 */
export const StyledSelectBranch = styled.div`
  margin-bottom: ${rem(22)};
  ul {
    display: flex;
    flex-wrap: wrap;
    li {
      ${thejamsilFont('light')}
      text-align: center;
      box-shadow: 0 0 0 1px ${({ theme }) => theme.colors.gray01};
      border-radius: ${rem(6)};
      font-size: ${rem(13)};
      padding: ${rem(12)};
      box-sizing: border-box;
      margin: 0 ${rem(8)} ${rem(8)} 0;
      :last-child {
        margin-right: 0rem;
      }
      &.active {
        ${thejamsilFont('medium')}
        background-color: black;
        color: white;
      }
    }
  }
`;

/**
 * @name 지역검색 스타일 컴포넌트
 */
export const StyledSelectRegion = styled.div`
  .branch_search {
    position: relative;
    border: 1px solid ${({ theme }) => theme.colors.gray01};
    border-radius: 0.3rem;
    box-sizing: border-box;
    height: ${rem(50)};
    input {
      border-radius: 0.3rem;
      padding: 0 0.7rem;
      width: 85%;
      height: 100%;
      outline: none;
      border: none;
      box-sizing: border-box;
      vertical-align: top;
      ${thejamsilFont('medium')}
      ::placeholder {
        ${thejamsilFont('light')}
        color: ${({ theme }) => theme.colors.gray01};
      }
    }
    p {
      position: absolute;
      width: ${rem(24)};
      height: ${rem(24)};
      right: ${rem(15)};
      top: 50%;
      transform: translateY(-50%);
    }
  }
`;

/**
 * @name 결과목록 스타일 컴포넌트
 */
export const StyledSelectResult = styled.div`
  .sub_title {
    margin: 0 ${rem(20)} ${rem(10)};
  }
  .location_con {
    overflow-y: auto;
    height: calc(100vh - 430px);
    padding-bottom: ${rem(200)};
    background-color: white;
    -webkit-overflow-scrolling: touch;
    li {
      width: 90%;
    }
  }
`;

/**
 * @name 주이용점포 카드 스타일 컴포넌트
 */
export const StyledBranchCardWrapper = styled.li`
  position: relative;
  box-sizing: border-box;
  padding: ${rem(20)};
  margin: auto;
  border-radius: ${rem(10)};
  box-shadow: 0px 10px 30px 0 rgba(0, 0, 0, 0.1);
  margin-bottom: ${rem(20)};
  width: 90%;
  .date_area {
    div {
      width: 80%;
    }
  }
`;

/**
 * @name 주이용점포 슈퍼 스타일 컴포넌트
 */
export const StyledSelectResultSuper = styled.div`
  height: 100vh;
  h3 {
    font-size: ${rem(14)};
    line-height: ${rem(22)};
    text-align: center;
    padding-top: ${rem(40)};
  }
`;
