import { UserAction } from '../actions/userActions';
import ActionTypes from '../constants/userConstats';
import { TUserState } from '../types/userTypes';

export const initialState: TUserState = {
  mainStoreState: {
    str_cd: '',
    str_nm: '',
    main_str_type: '',
  },
  tenantStoreState: {
    str_cd: '',
    str_nm: '',
    main_str_type: '',
  },
  userInfoState: {
    emp_yn: 'N',
    member_app_card_no: '',
    member_grade: '1',
    member_grade_nm: 'ACE',
    member_name: '',
  },
  currenTime: '',
};

function UserReducer(
  state: TUserState = initialState,
  action: UserAction,
): TUserState {
  switch (action.type) {
    case ActionTypes.SET_MAINSTORE_STATE:
      return {
        ...state,
        mainStoreState: action.payload.mainStoreState,
      };
    case ActionTypes.SET_TENANTSTORE_STATE:
      return {
        ...state,
        tenantStoreState: action.payload.tenantStoreState,
      };
    case ActionTypes.SET_USERINFO_STATE:
      return {
        ...state,
        userInfoState: action.payload.userInfoState,
      };
    case ActionTypes.SET_CURRENT_TIME:
      return {
        ...state,
        currenTime: action.payload.currentTime,
      };
    default:
      return state;
  }
}

export default UserReducer;
