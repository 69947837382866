import React, { useRef } from 'react';
import { StyledImage } from './styles';
import { IMAGE_ROOT } from 'src/utils/constants';

type TDefaultImgType = 'PRODUCT' | 'ETC';

type TImageProps = React.ImgHTMLAttributes<HTMLImageElement> &
  TImageStyleProps & {
    onError?: () => void;
    defaultImageType?: TDefaultImgType;
  };

export type TImageStyleProps = {
  borderRadius?: string;
};

export function Image({
  onError,
  defaultImageType,
  ...props
}: TImageProps): React.ReactElement {
  const imgRef = useRef<HTMLImageElement>(null);

  const defaultImgType = defaultImageType
    ? DEFAULT_IMAGE_TYPE[defaultImageType]
    : DEFAULT_IMAGE_TYPE['ETC'];

  const handleImageError = () => {
    if (onError) {
      onError();
    } else {
      imgRef.current?.setAttribute(
        'src',
        `${IMAGE_ROOT}/default/${defaultImgType}`,
      );
    }
  };

  return <StyledImage {...props} ref={imgRef} onError={handleImageError} />;
}

const DEFAULT_IMAGE_TYPE = {
  PRODUCT: 'default_product.jpg',
  ETC: 'noimg.jpg',
};
