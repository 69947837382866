import apiPsp from 'src/utils/apiPsp';

/**
 * @name 스노우플랜이용내역 조회
 */

export const apiGetSnowplan = async () => {
  try {
    const response = await apiPsp.post('/gifton/trn', {
      id: localStorage.psp_id,
    });
    const data = await response.data;
    return data;
  } catch (err) {
    console.log(err);
    throw err;
  }
};

/**
 * @name 스노우플랜잔액 조회
 */

export const apiGetSnowplanBalance = async () => {
  const response = await apiPsp.post('/gifton/balance', {
    id: localStorage.psp_id,
  });
  const data = await response.data.result_data.order_balance;
  return data || 0;
};

/**
 * @name 스노우플랜정보 조회
 */
export const apiGeTSnowPlanInfo = async () => {
  const response = await apiPsp.post('/gifton/opt-num', {
    id: localStorage.psp_id,
  });
  const data = await response.data.result_data;
  if (data) {
    return data;
  } else {
    throw new Error('데이터를 가져오는 데 문제가 발생했습니다.');
  }
};

/**
 * @name 포인트스노우플랜정보 조회
 */
export const apiGetSnowplanTargetGrade = async () => {
  const response = await apiPsp.get('/user/target-grade', {
    params: { id: localStorage.psp_id },
  });

  const data = response.data;

  if (data) {
    return data;
  } else {
    throw new Error('데이터를 가져오는 데 문제가 발생했습니다.');
  }
};

/**
 * @name 스노우플랜대상금액 조회
 */
export const apiGetSnowplanTargetHistory = async monthParam => {
  const response = await apiPsp.get('/user/sales/hist/month', {
    params: { id: localStorage.psp_id, month: monthParam },
  });
  return response;
};

/**
 * @name 스노우플랜영수증 조회
 */

export const apiGetSnowplanReceiptInfo = async SerchNoParams => {
  const response = await apiPsp.post('/gifton/receipt', {
    custId: localStorage.psp_id,
    searchNo: SerchNoParams,
  });

  const data = await response.data;
  return data;
};

/**
 * @name 스노우플랜cs쿠폰 발행
 *
 */
export const apiCsCoupon = async csParams => {
  const response = await apiPsp.post('/gifton/cs', [...csParams]);
  return response;
};

/**
 * @name 스노우플랜미스캔 배치
 */
export const apiMissScanRequire = async () => {
  const response = await apiPsp.post('/gifton/unissued', {
    id: localStorage.psp_id,
  });
  return response;
};

/**
 * @name 스노우플랜 사용금액 구간별 조회
 */
export const apiSnoWPlanSectionInfo = async () => {
  const response = await apiPsp.get('/statistics/usage-point-section');
  return response;
};

/**
 * @name 스노우플랜이벤트 적립
 */
export const apiSnowPlanEventReward = async snowPlanEvtParams => {
  const response = await apiPsp.post('/gifton/event/issue', {
    ...snowPlanEvtParams,
  });
  return response;
};
/**
 * @name 스노우플랜이벤트 참여여부
 */
export const apiSnowPlanEventCheck = async evtParams => {
  const response = await apiPsp.get('/gifton/event/check', {
    params: { ...evtParams },
  });
  return response;
};
/**
 * @name 스노우플랜 전환 가능 포인트 조회
 */
export const apiSnowPlanConvertiblePoints = async id => {
  const response = await apiPsp.post('/gifton/lpoint/check', {
    id,
  });
  return response;
};

/**
 * @name 스노우플랜 L.point전환
 */

export const apiSnowPlanEXChangeLpoint = async amountParmas => {
  const response = await apiPsp.post('/gifton/lpoint/exchange', {
    ...amountParmas,
  });
  return response;
};
