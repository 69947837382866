import { ActionType } from 'typesafe-actions';
import * as actions from '../actions/mainActions';
import { E_POPUP_STATE } from 'src/utils/enum';
export enum E_MAIN_MENU_STATE {
  'OPEN' = 'OPEN',
  'CLOSE' = 'CLOSE',
  'NONE' = '',
}

export type TMainState = {
  mainMenuState: E_MAIN_MENU_STATE;
  isOpenMartSearchPopup: E_POPUP_STATE;
};

export type MainAction = ActionType<typeof actions>;
