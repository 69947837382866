enum ActionTypes {
  OPEN_MART_CHANGE_POPUP = 'martChangePopup/OPEN_MART_CHANGE_POPUP',
  CLOSE_MART_CHANGE_POPUP = 'martChangePopup/CLOSE_MART_CHANGE_POPUP',
  OPEN_TENANT_COUPON_POPUP = 'tenantCouponPopup/OPEN_TENANT_COUPON_POPUP',
  CLOSE_TENANT_COUPON_POPUP = 'tenantCouponPopup/CLOSE_TENANT_COUPON_POPUP',
  SET_STORE_COUPON_POPUP_INFO = 'tenantCouponPopup/SET_STORE_COUPON_POPUP_INFO',
  SET_STORE_EVENT_COUPON_LIST = 'tenantCouponPopup/SET_STORE_EVENT_LIST',
}

export default ActionTypes;
