import api from 'src/utils/api';
import { E_API_SERVICEID } from 'src/utils/enum';

/**
 * @name 장바구니 삭제
 * @param str_cd 점포코드
 * @param del_fg 삭제구분 Y:전체삭제/N:부분삭제
 * @param prod_seq 삭제 상품 순번 (장바구니)
 * @param prod_cd 삭제 상품 코드 (징바구니)
 */
export const apiRemoveBasket = async params => {
  const response = await api.post(
    '/fo/bsket/delete',
    { ...params },
    {
      headers: {
        'x-ssp-gateway-service-id': E_API_SERVICEID.SCANGO,
      },
    },
  );
  const data = await response.data;

  return data;
};

/**
 * @name 할인내역조회 (결제금액 내 할인내용확인 팝업)
 * @param str_cd 점포코드
 */
export const apiGetDiscountList = async params => {
  const response = await api.post(
    '/fo/bsket/discount',
    { ...params },
    {
      headers: {
        'x-ssp-gateway-service-id': E_API_SERVICEID.SCANGO,
      },
    },
  );
  const data = await response.data;

  return data;
};

/**
 * @name 결제내용
 * @param str_cd 점포코드
 * @param pay_fg 결제재계산구분
 * @param card_isu_cd 결제카드
 * @param card_setl_fg 결제카드 할부
 * @param card_nm 결제카드명
 * @param lpoint_amt 사용포인트
 * @param cpn_cd 금액쿠폰할인
 * @param prst_list 할인증정선택행사목록
 */
export const apiScanGoCreatePay = async params => {
  const response = await api.post(
    '/fo/discount/createPay',
    { ...params },
    {
      headers: {
        'x-ssp-gateway-service-id': E_API_SERVICEID.SCANGO,
      },
    },
  );
  const data = await response.data;

  return data;
};

/**
 * @name 결제내역 목록 조회
 * @param ord_fg 주문구분  1
 * @param sale_date 매출일자 YYYYMMDD
 */
export const apiGetScanGoHistorytList = async params => {
  const response = await api.post(
    '/fo/order/paylist',
    { ...params },
    {
      headers: {
        'x-ssp-gateway-service-id': E_API_SERVICEID.SCANGO,
      },
    },
  );

  const data = await response.data;

  return data;
};

/**
 * @name 결제내역 년월 조회
 * @param ord_fg 주문구분 1
 */
export const apiGetScanGoHistoryMonth = async params => {
  const response = await api.post(
    '/fo/order/month',
    { ...params },
    {
      headers: {
        'x-ssp-gateway-service-id': E_API_SERVICEID.SCANGO,
      },
    },
  );
  const data = await response.data.payment_month;

  return data;
};

/**
 * @name 결제내역 상세 조회
 * @param ord_no 주문번호
 */
export const apiGetScanGoHistoryDetail = async params => {
  const response = await api.post(
    '/fo/order/paydtlist',
    {
      ...params,
    },
    {
      headers: {
        'x-ssp-gateway-service-id': E_API_SERVICEID.SCANGO,
      },
    },
  );
  const data = await response.data;

  return data;
};

/**
 * @name 스캔앤고 결제취소 상품 다시 담기
 * @param ord_no 주문번호
 */
export const apiAddBasket = async params => {
  const response = await api.post(
    '/fo/bsket/canceledprod',
    {
      ...params,
    },
    {
      headers: {
        'x-ssp-gateway-service-id': E_API_SERVICEID.SCANGO,
      },
    },
  );
  const data = await response.data;

  return data;
};

/**
 * @name 스캔앤고 결제완료 조회
 * @param ord_no 거래번호
 */
export const apiGetScangoComplete = async params => {
  const response = await api.post(
    '/fo/order/complete',
    { ...params },
    {
      headers: {
        'x-ssp-gateway-service-id': E_API_SERVICEID.SCANGO,
      },
    },
  );
  const data = await response.data;

  return data;
};

/**
 * @name 스마트영수증 상세 조회
 * @param str_cd 점포코드
 * @param ord_no 주문번호
 */
export const apiGetReceiptDetail = async params => {
  const response = await api.post(
    '/fo/receipt/smart/show',
    {
      str_cd: params.str_cd,
      ord_no: params.ord_no,
    },
    {
      headers: { 'x-ssp-gateway-service-id': E_API_SERVICEID.SCANGO },
    },
  );
  const data = await response.data;
  return data;
};

/**
 * @name 스마트영수증 상세 조회
 * @param str_cd 점포코드
 * @param ord_no 주문번호
 */
export const apiGetCancelReceiptDetail = async params => {
  const response = await api.post(
    '/fo/receipt/smart/cancel/show',
    {
      str_cd: params.str_cd,
      ord_no: params.ord_no,
    },
    {
      headers: { 'x-ssp-gateway-service-id': E_API_SERVICEID.SCANGO },
    },
  );
  const data = await response.data;
  return data;
};

/**
 * @name 결제 직원인증
 * @param str_cd 점포코드
 * @param auth_no 인증한 직원ID
 */
export const apiAuthEmployee = async params => {
  const response = await api.post(
    '/fo/order/admincomplete',
    { ...params },
    {
      headers: {
        'x-ssp-gateway-service-id': E_API_SERVICEID.SCANGO,
      },
    },
  );
  const data = await response.data;

  return data;
};

/**
 * @name 결제하기 버튼
 * @param str_cd 점포코드
 * @param lpoint L.POINT 사용금액
 * @param card_isu_fg 카드사 번호
 * @param final_setl_amt 결제 금액
 * @param card_setl_fg 할부 개월 수
 */
export const apiCreatePayment = async params => {
  const response = await api.post(
    '/fo/order/create',
    {
      ...params,
    },
    {
      headers: {
        'x-ssp-gateway-service-id': E_API_SERVICEID.SCANGO,
      },
    },
  );
  const data = await response.data;

  return data;
};

/**
 * @name 구매한 봉투조회
 * @param str_cd 점포코드
 * @param ord_no 주문번호
 */
export const apiGetBuyBagList = async params => {
  const response = await api.post(
    '/fo/bsket/buybaglist',
    {
      ...params,
    },
    {
      headers: {
        'x-ssp-gateway-service-id': E_API_SERVICEID.SCANGO,
      },
    },
  );

  const data = await response.data;

  return data;
};

/**
 * @name 혜택 조회
 * @param str_cd 점포코드
 */
export const apiGetBenefit = async str_cd => {
  const response = await api.post(
    '/fo/bsket/card/benefits',
    {
      str_cd: str_cd,
    },
    {
      headers: {
        'x-ssp-gateway-service-id': E_API_SERVICEID.SCANGO,
      },
    },
  );

  const data = await response.data;

  return data;
};

/**
 * @name 장바구니 목록 조회
 * @param str_cd 점포코드
 */
export const apiGetBasketList = async str_cd => {
  const response = await api.post(
    '/fo/bsket/list',
    {
      str_cd: str_cd,
    },
    {
      headers: {
        'x-ssp-gateway-service-id': E_API_SERVICEID.SCANGO,
      },
    },
  );

  const data = await response.data;

  return data;
};
