import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch } from 'react-redux';
import JsBarcode from 'jsbarcode';

import {
  actionCloseAlertPopup,
  actionCloseLoadingPopup,
  actionCloseSnowPlanUsePopup,
  actionOpenAlertPopup,
  actionOpenLoadingPopup,
} from 'src/redux/actions/appActions';

import { StyledSnowPlanUsePopup } from '../styles';
import { IcoClose, IcoReset } from 'src/components/Icon';

import { E_POPUP_STATE } from 'src/utils/enum';
import { useQueries, useQueryClient } from '@tanstack/react-query';
import { commafy } from 'src/utils/utility';

import SnowPointTimer from './SnowPointTimer';

import { IcoClock } from 'src/components/Icon';
import { apiGetSnowplanBalance, apiGeTSnowPlanInfo } from 'src/api/apiSnowPlan';
import { rem } from 'src/styles/function';
import { bridgeBarcodeClose, bridgeBarcodeOpen } from 'src/utils/bridge';
import { gaPageView } from 'src/utils/ga';
import { E_QUERY_KEY } from 'src/utils/queryKey';
import { PopupStopUseBarcode } from 'src/components/Popup/components/PopupMessages';

interface TProps {
  isOpen: E_POPUP_STATE;
}

function SnowPlanUsePopup(props: TProps): React.ReactElement {
  const dispatch = useDispatch();
  const queryClient = useQueryClient();

  const [isExpired, setIsExpired] = useState(false);

  const handleClickClose = () => {
    dispatch(actionCloseSnowPlanUsePopup());
    //화면 밝기 원복
    bridgeBarcodeClose();
  };

  // 스노우플랜 OTP, 잔액 조회
  const queries = useQueries({
    queries: [
      {
        queryKey: E_QUERY_KEY.SNOWPLAN_DETAIL_INFO,
        queryFn: apiGeTSnowPlanInfo,
      },
      {
        queryKey: E_QUERY_KEY.SNOWPLAN_DETAIL_BALAMCE,
        queryFn: apiGetSnowplanBalance,
      },
    ],
  });

  // 스노우플랜 OTP
  const snowPointInfo = useMemo(() => {
    return queries[0].data;
  }, [queries]);
  // 스노우플랜 잔액
  const snowPointBalance = useMemo(() => {
    return queries[1].data;
  }, [queries]);

  //OTP 유효시간 만료 체크
  const getIsExpired = useCallback((val: boolean) => {
    setIsExpired(val);
  }, []);

  //새로고침 버튼
  const handleClickRefreshBtn = useCallback(() => {
    queryClient.invalidateQueries(E_QUERY_KEY.SNOWPLAN_DETAIL_INFO);
    queryClient.invalidateQueries(E_QUERY_KEY.SNOWPLAN_DETAIL_BALAMCE);
    setIsExpired(false);
  }, [queryClient]);

  // OTP 바코드 생성
  useEffect(() => {
    if (snowPointInfo && snowPointInfo.integrated_otp_num) {
      if (snowPointInfo.integrated_otp_num === '9999999999999999') {
        // alert('LPOINT 전환 신청 처리 중입니다. 이용이 제한 됩니다. ');
        // dispatch(actionCloseLoadingPopup());
        // dispatch(actionCloseSnowPlanUsePopup());
        // bridgeBarcodeClose();

        dispatch(
          actionOpenAlertPopup({
            children: <PopupStopUseBarcode />,
            onClose: () => {
              dispatch(actionCloseSnowPlanUsePopup());
              dispatch(actionCloseAlertPopup());
              bridgeBarcodeClose();
              dispatch(actionCloseLoadingPopup());
            },
          }),
        );
      } else {
        const barcodeString =
          snowPointInfo.integrated_otp_num.substring(0, 4) +
          '  ' +
          snowPointInfo.integrated_otp_num.substring(4, 8) +
          '  ' +
          snowPointInfo.integrated_otp_num.substring(8, 12) +
          '  ' +
          snowPointInfo.integrated_otp_num.substring(12, 16);

        setTimeout(() => {
          JsBarcode('#barcode-img', snowPointInfo.integrated_otp_num, {
            background: '#ffffff',
            text: barcodeString,
            height: 70,
            textAlign: 'center',
            fontSize: 15,
          });
        }, 0);
      }
    }
  }, [dispatch, snowPointInfo]);

  useEffect(() => {
    // 화면 밝기 최대
    bridgeBarcodeOpen();
  }, []);

  useEffect(() => {
    gaPageView(location.pathname, '', `스노우플랜 사용 팝업`);
  }, []);

  useEffect(() => {
    if (queries[0].isLoading || queries[0].isFetching) {
      dispatch(actionOpenLoadingPopup());
    } else {
      dispatch(actionCloseLoadingPopup());
    }
  }, [dispatch, queries]);

  return (
    <StyledSnowPlanUsePopup>
      {props.isOpen === 'OPEN' && (
        <>
          <div className="pop_header">
            <h3 className="title">스노우플랜 사용</h3>
            <div className="close">
              <button onClick={handleClickClose}>
                <i>
                  <IcoClose />
                </i>
              </button>
            </div>
          </div>
          {isExpired ? (
            <>
              <div className="time">
                <i>
                  <IcoClock />
                </i>
                <p className="dec">인증 유효시간 초과</p>
              </div>
            </>
          ) : (
            snowPointInfo &&
            snowPointInfo.time_left && (
              <SnowPointTimer
                timeLeft={snowPointInfo.time_left}
                getIsExpired={getIsExpired}
              />
            )
          )}
          <div className="card_box">
            <div className="point_area">
              <div className="title">사용 가능</div>
              <div className="point">
                {snowPointBalance
                  ? commafy(Number(snowPointBalance))
                  : queries[1].isSuccess
                  ? 0
                  : '-'}
              </div>
              <div className="p">p</div>
            </div>
            <div className="barcode">
              <svg id="barcode-img"> </svg>
              {isExpired && (
                <div className="btn_box">
                  <div className="btn_refresh">
                    <button onClick={handleClickRefreshBtn}>
                      <i>
                        <IcoReset />
                      </i>
                    </button>
                  </div>
                </div>
              )}
            </div>
          </div>
          <p>
            보유 스노우플랜 전체가 사용되지 않을 경우 <br />
            현재 조회된 스노우플랜을 먼저 사용하신 후,
            <br />
            바코드를 다시 조회해주세요.
          </p>
          <p style={{ marginTop: `${rem(15)}` }}>
            * 스노우플랜 사용 시, 카드할인 불가
          </p>
        </>
      )}
    </StyledSnowPlanUsePopup>
  );
}

export default SnowPlanUsePopup;
