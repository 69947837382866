import { TLoginAppleSave } from 'src/containers/AutoLogin/types';
import { TBranchTerms } from 'src/types/types';
import api from 'src/utils/api';
import {
  bridgeDeleteCache,
  bridgeLogout,
  bridgeSetAutoLogin,
} from 'src/utils/bridge';
import { E_API_SERVICEID } from 'src/utils/enum';

/**
 * @name 사용자 정보 조회
 */
export const apiGetUserInfo = async () => {
  const response = await api.post(
    '/fo/user/main/user/info',
    {},
    {
      headers: {
        'x-ssp-gateway-service-id': E_API_SERVICEID.USER,
      },
    },
  );
  const data = await response.data;

  return data;
};

/**
 * @name 주이용점포 조회
 */
export const apiGetMainStore = async () => {
  const response = await api.post(
    '/fo/user/main/store/main/get',
    {},
    {
      headers: { 'x-ssp-gateway-service-id': E_API_SERVICEID.USER },
    },
  );

  const data = await response.data;
  if (data) {
    return data;
  } else {
    return { str_cd: '301', str_nm: '강변점', main_str_type: 'HYPER' };
  }
};

/**
 * @name 지역 조회
 */
export const apiGetRegion = async () => {
  const response = await api.post(
    '/fo/user/main/region/list',
    {},
    {
      headers: { 'x-ssp-gateway-service-id': E_API_SERVICEID.USER },
    },
  );
  const data = await response.data.list;

  return data;
};

/**
 * @name 점포 목록 조회
 * @param str_nm 검색어
 * @param region_cd 지역코드
 * @param main_str_type 점포유형
 */
export const apiGetBranchList = async (input: TBranchTerms) => {
  const response = await api.post('/fo/user/main/store/list', input, {
    headers: { 'x-ssp-gateway-service-id': E_API_SERVICEID.USER },
  });
  const data = await response.data.list;
  return data;
};

/**
 * @name 점포 상세 조회
 * @param strCd 점포코드
 */
export const apiGetBranchDetail = async strCd => {
  const response = await api.post(
    '/fo/user/main/store/info',
    {
      str_cd: strCd,
    },
    {
      headers: { 'x-ssp-gateway-service-id': E_API_SERVICEID.USER },
    },
  );
  const data = await response.data;
  return data;
};

/**
 * @name 주이용점포 변경
 * @param strCd 점포코드
 */
export const apiSetChangeMainStore = async strCd => {
  const response = await api.post(
    '/fo/user/main/store/main/update',
    {
      str_cd: strCd,
    },
    {
      headers: { 'x-ssp-gateway-service-id': E_API_SERVICEID.USER },
    },
  );
  const data = await response.data;
  return data;
};

/**
 * @name 알림, 공지사항 뱃지 조회
 */
export const apiGetPushCnt = async () => {
  const response = await api.post(
    '/fo/user/main/push/cnt',
    {},
    {
      headers: { 'x-ssp-gateway-service-id': E_API_SERVICEID.USER },
    },
  );
  const data = await response.data;
  return data;
};

/**
 * @name 스마트영수증 탄소중립 동의자 수 조회
 */
export const apiGetSmartReceiptCnt = async () => {
  const response = await api.post(
    '/fo/user/receipt/smart/cnt',
    {},
    {
      headers: { 'x-ssp-gateway-service-id': E_API_SERVICEID.USER },
    },
  );
  const data = await response.data.smart_only_cnt;

  return data;
};

/**
 * @name 설정 사용자 약관 목록(동의값) 조회
 */
export const apiGetAgmtYn = async () => {
  const response = await api.post(
    '/fo/user/setting/agmt/list',
    {},
    {
      headers: { 'x-ssp-gateway-service-id': E_API_SERVICEID.USER },
    },
  );
  const data = await response.data;

  return data;
};

/**
 * @name 설정 마케팅/PUSH/CI 동의값 설정
 */
export const apiGetAgmtUpdate = async params => {
  const response = await api.post('/fo/user/setting/agmt/update', params, {
    headers: { 'x-ssp-gateway-service-id': E_API_SERVICEID.USER },
  });
  const data = await response.data;

  return data;
};

/**
 * @name 설정 로그아웃
 */
export const apiUserLogout = async () => {
  const response = await api
    .post(
      '/fo/user/setting/logout',
      {},
      {
        headers: { 'x-ssp-gateway-service-id': E_API_SERVICEID.USER },
      },
    )
    .catch(() => {
      //로그아웃 오류 시에도 로그아웃 강제 처리
      bridgeSetAutoLogin('N');
      bridgeLogout();
      bridgeDeleteCache('');
      localStorage.clear();
      sessionStorage.clear();
      location.href = '/login';
    });

  const data = await response?.data;

  return data;
};

/**
 * @name 설정 앱 버전 및 아이디 조회
 */
export const apiGetAppVerId = async params => {
  const response = await api.post('/fo/user/setting/version', params, {
    headers: { 'x-ssp-gateway-service-id': E_API_SERVICEID.USER },
  });
  const data = await response.data;

  return data;
};

/**
 * @name 설정 마트GO 탈퇴
 */
export const apiUserWithdraw = async () => {
  const response = await api.post(
    '/fo/user/setting/withdraw',
    {},
    {
      headers: { 'x-ssp-gateway-service-id': E_API_SERVICEID.USER },
    },
  );
  const data = await response.data;

  return data;
};

/**
 * @name 설정 마트GO 회원여부 조회
 */
export const apiUserIsMember = async () => {
  const response = await api.post(
    '/fo/user/setting/ismember',
    {},
    {
      headers: { 'x-ssp-gateway-service-id': E_API_SERVICEID.USER },
    },
  );
  const data = await response.data;

  return data;
};

/**
 * @name 설정 애플로그인 연동해제
 */
export const apiUserAppleLoginClear = async (membs_on_cno: string) => {
  const response = await api.post(
    '/fo/user/setting/apple-clear',
    {
      membs_on_cno: membs_on_cno,
    },
    {
      headers: { 'x-ssp-gateway-service-id': E_API_SERVICEID.USER },
    },
  );
  const data = await response.data;

  return data;
};

/**
 * @name 설정 애플로그인 연동등록
 */
export const apiUserAppleLoginSave = async (params: TLoginAppleSave) => {
  const response = await api.post('/fo/user/setting/apple-save', params, {
    headers: { 'x-ssp-gateway-service-id': E_API_SERVICEID.USER },
  });
  const data = await response.data;

  return data;
};

/**
 * @name 설정 멤버스 소셜로그인 연동조회
 */
export const apiUserMembsSocialInfo = async (acesTkn: string) => {
  const response = await api.post(
    '/fo/user/setting/membs/social-info',
    {
      acesTkn: acesTkn,
    },
    {
      headers: { 'x-ssp-gateway-service-id': E_API_SERVICEID.USER },
    },
  );
  const data = await response.data;

  return data;
};

/**
 * @name 설정 멤버스 소셜로그인 연동등록
 */
export const apiUserMembsSociaSave = async params => {
  const response = await api.post(
    '/fo/user/setting/membs/social-save',
    params,
    {
      headers: { 'x-ssp-gateway-service-id': E_API_SERVICEID.USER },
    },
  );
  const data = await response.data;

  return data;
};

/**
 * @name 설정 멤버스 소셜로그인 연동등록
 */
export const apiUserMembsSociaClear = async params => {
  const response = await api.post(
    '/fo/user/setting/membs/social-clear',
    params,
    {
      headers: { 'x-ssp-gateway-service-id': E_API_SERVICEID.USER },
    },
  );
  const data = await response.data;

  return data;
};

/**
 * @name 알림 목록 조회
 */
export const apiGetNoticePushList = async () => {
  const response = await api.post(
    '/fo/user/notice/push/list',
    {},
    {
      headers: { 'x-ssp-gateway-service-id': E_API_SERVICEID.USER },
    },
  );
  const data = await response.data;

  return data;
};

/**
 * @name 알림 삭제
 * @param large_yn 대용량PUSH 여부
 * @param seq_no PUSH번호
 */
export const apiGetDeleteNoticePush = async params => {
  const response = await api.post(
    '/fo/user/notice/push/delete',
    { large_yn: params.large_yn, seq_no: params.seq_no },
    {
      headers: { 'x-ssp-gateway-service-id': E_API_SERVICEID.USER },
    },
  );
  const data = await response.data;

  return data;
};

/**
 * @name 임직원 인증 여부 조회
 */
export const apiCheckEmpAuth = async () => {
  const response = await api.post(
    '/fo/user/emp/auth/check',
    {},
    {
      headers: { 'x-ssp-gateway-service-id': E_API_SERVICEID.USER },
    },
  );
  const data = await response.data;

  return data;
};

/**
 * @name 임직원 정보 조회
 */
export const apiGetEmpInfo = async () => {
  const response = await api.post(
    '/fo/user/emp/info',
    {},
    {
      headers: { 'x-ssp-gateway-service-id': E_API_SERVICEID.USER },
    },
  );
  const data = await response.data;

  return data;
};

/**
 * @name 임직원 인증 요청
 */
export const apiRequestEmpAuth = async params => {
  const response = await api.post(
    '/fo/user/emp/auth/request',
    {
      emp_no: params.emp_no,
      id: params.id,
    },
    {
      headers: { 'x-ssp-gateway-service-id': E_API_SERVICEID.USER },
    },
  );
  const data = await response.data;

  return data;
};

/**
 * @name 임직원 인증 처리
 */
export const apiCompleteEmpAuth = async params => {
  const response = await api.post(
    '/fo/user/emp/auth/complete',
    {
      cno: '',
      emp_no: params.emp_no,
      id: params.id,
      cert_no: params.cert_no,
    },
    {
      headers: { 'x-ssp-gateway-service-id': E_API_SERVICEID.USER },
    },
  );
  const data = await response.data;

  return data;
};

/**
 * @name 1:1건의 목록 조회
 */
export const apiGetQnaList = async () => {
  const response = await api.post(
    '/fo/user/voc/list',
    {},
    {
      headers: { 'x-ssp-gateway-service-id': E_API_SERVICEID.USER },
    },
  );
  const data = await response.data;
  return data;
};

/**
 * @name 1:1건의 등록
 */
export const apiGetQnaRegister = async params => {
  const response = await api.post(
    '/fo/user/voc/insert',
    {
      ...params,
    },
    {
      headers: { 'x-ssp-gateway-service-id': E_API_SERVICEID.USER },
    },
  );
  const data = await response.data;

  return data;
};

/**
 * @name 서버시간 조회
 */
export const apiGetCurrentTime = async () => {
  const response = await api.post(
    '/fo/user/common/current-time',
    {},
    {
      headers: { 'x-ssp-gateway-service-id': E_API_SERVICEID.USER },
    },
  );
  const data = await response.data;

  return data;
};

/**
 * @name 엘포인트 조회
 */
export const apiGetLpoint = async () => {
  const response = await api.post(
    '/fo/user/common/lpoint',
    {},
    {
      headers: { 'x-ssp-gateway-service-id': E_API_SERVICEID.USER },
    },
  );

  const data = response.data;

  return data;
};

/**
 * @name 외부진입 Log 저장
 * @param login_divn_out_dvs 외부진입 구분 값
 * @param login_divn_dvs 로그인 구분 값 (G:외부진입 고정)
 */
export const apiSetConnLog = async (login_divn_out_dvs: string) => {
  const response = await api.post(
    '/fo/user/common/conn/log',
    { login_divn_out_dvs: login_divn_out_dvs, login_divn_dvs: 'G' },
    {
      headers: { 'x-ssp-gateway-service-id': E_API_SERVICEID.USER },
    },
  );

  const data = response.data;

  return data;
};

/**
 * @name CRM Log 저장
 * @param scd 시스템 구분코드
 * @param tno 추적번호
 * @param url 접속url
 */
export const apiSetConnCrmLog = async params => {
  const response = await api.post('/fo/user/common/crm/outurl/log', params, {
    headers: { 'x-ssp-gateway-service-id': E_API_SERVICEID.USER },
  });

  const data = response.data;

  return data;
};

/**
 * @name PUSH Log 저장(읽음처리)
 * @param large_yn 대용량 PUSH 여부
 * @param seq_no PUSH 번호
 */
export const apiSetConnPushLog = async params => {
  const response = await api.post('/fo/user/common/push/read', params, {
    headers: { 'x-ssp-gateway-service-id': E_API_SERVICEID.USER },
  });

  const data = response.data;

  return data;
};
