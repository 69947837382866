import styled from 'styled-components';
import { rem } from 'src/styles/function';
import { thejamsilFont } from 'src/styles/font';

/**
 * @name 약관팝업 컴포넌트
 */
export const StyledTerms = styled.section`
  overflow-y: auto;
  width: 100%;
  height: calc(95vh - 100px);
  padding-bottom: 2rem;
  div {
    ${thejamsilFont('light')}
    font-size: ${rem(14)};
    color: #666;
    padding: ${rem(20)};
    line-height: ${rem(24)};
    h3 {
      ${thejamsilFont('regular')}
      font-size: ${rem(14)};
      line-height: 1.3;
    }
    h3:not(:first-of-type) {
      margin-top: 1.02564rem;
    }
    p {
      margin-bottom: ${rem(15)};
      line-height: 1.3;
    }
    span {
      &.red {
        color: ${({ theme }) => theme.colors.red};
      }
    }
  }
`;
