import React, { useEffect, useMemo } from 'react';
import { useDispatch } from 'react-redux';
import { useQueries } from '@tanstack/react-query';

import { SlideUpPopupWrapper } from 'src/components/Popup/components/SlideUpPopup';
import { StyledAppCard } from './styles';

import {
  actionCloseAppCardPouup,
  actionCloseSnowPlanUsePopup,
  actionGetScangoBanner,
  actionOpenScangoIntroPopup,
  actionOpenSnowPlanUsePopup,
  actionOpenStartScango,
} from 'src/redux/actions/appActions';
import JsBarcode from 'jsbarcode';
import { E_POPUP_STATE } from 'src/utils/enum';
import { Button } from 'src/components/Button';
import Dim from 'src/components/Dim';
import { commafy, isAndWebView, isIOSWebView } from 'src/utils/utility';
import { apiGetSnowplanBalance } from 'src/api/apiSnowPlan';

import { thejamsilFont } from 'src/styles/font';
import useSelectorEqual from 'src/hooks/useSelectorEqual';
import { RootState } from 'src/store/reducers';
import {
  bridgeBarcodeClose,
  bridgeBarcodeOpen,
  bridgeOpenAppScheme,
} from 'src/utils/bridge';
import { apiGetBanner } from 'src/api/apiEvent';
import { TBanner } from 'src/types/types';
import { apiGetLpoint } from 'src/api/apiUser';
import { SUPER_CHECK } from 'src/utils/constants';
import { gaEvent, gaPageView } from 'src/utils/ga';
import { E_QUERY_KEY } from 'src/utils/queryKey';
import { IcoAppCardClose } from 'src/components/Icon';

type Props = {
  appCardPopupState: E_POPUP_STATE;
};

function AppCard(props: Props): React.ReactElement {
  const pageName = '앱 카드 팝업';

  const [mainStoreState, userInfoState] = useSelectorEqual(
    (state: RootState) => [state.user.mainStoreState, state.user.userInfoState],
  );

  // Lpoint, 스노우, 스캔앤고 배너 조회
  const queries = useQueries({
    queries: [
      {
        queryKey: E_QUERY_KEY.USER_DETAIL_LPOINT,
        queryFn: apiGetLpoint,
      },
      {
        queryKey: E_QUERY_KEY.SNOWPLAN_DETAIL_BALAMCE,
        queryFn: apiGetSnowplanBalance,
      },
      {
        queryKey: E_QUERY_KEY.EVENT_LIST_BANNER_SCANGO,
        queryFn: ({ queryKey }) => apiGetBanner(queryKey),
        onSuccess: (data: TBanner[]) => {
          dispatch(actionGetScangoBanner(data));
        },
      },
    ],
  });

  // lpoint
  const lPoint = useMemo(() => {
    return queries[0].data;
  }, [queries]);
  // 스노우플랜
  const snowPointBalance = useMemo(() => {
    return queries[1].data;
  }, [queries]);

  const dispatch = useDispatch();

  // 앱카드 닫기
  const onCloseAppCard = () => {
    dispatch(actionCloseAppCardPouup());
    // 화면 밝기 원복
    bridgeBarcodeClose();
  };

  //스노우플랜 사용하기 클릭
  const handleclickSnowPointUse = () => {
    gaEvent('스노우플랜 사용하기', '', '', pageName);

    if (!snowPointBalance || snowPointBalance === '0') {
      alert('스노우플랜 잔액이 없습니다.');
      dispatch(actionCloseSnowPlanUsePopup());
    } else {
      onCloseAppCard();
      dispatch(actionOpenSnowPlanUsePopup());
    }
  };

  // 스캔앤고 결제하기 클릭
  const handleClickSmartPayment = () => {
    gaEvent('스캔앤고 결제하기', '', '', pageName);

    const scangoIntroPopup = localStorage.getItem('scangoIntroPopup');

    if (scangoIntroPopup === 'N') {
      // 스캔앤고 인트로 팝업 다시 보지 않기 선택 시
      dispatch(actionOpenStartScango());
    } else {
      dispatch(actionOpenScangoIntroPopup());
    }
    onCloseAppCard();
  };

  // L.PAY 결제하기 클릭
  const handleClickLpay = () => {
    gaEvent('L.PAY 결제하기', '', '', pageName);
    //lpayapp://pay?acode=6110007&ccode=1350018&tcode=6000003&pcode={pcode}&callback={url 인코딩된 m쿠폰스키마}
    //acode : 6110007 바코드 요청코드 //ccode : 1350018 M쿠폰 유입코드 //tcode : 6000003 Offline 결제
    const barcode = userInfoState.member_app_card_no;
    const pcode = barcode + '001'; // 19자리 = 16자리 M쿠폰 바코드 + 3자리 '001' Lpay구분자
    const param =
      'lmslpay://pay?acode=6110007&ccode=1350018&tcode=6000003&pcode=' + pcode;

    if (isIOSWebView) {
      bridgeOpenAppScheme(
        param,
        'https://apps.apple.com/kr/app/losdemembeoseu/id473250588',
      );
    } else if (isAndWebView) {
      bridgeOpenAppScheme(param, 'com.lottemembers.android');
    }
  };

  // 바코드 생성
  useEffect(() => {
    if (!userInfoState.member_app_card_no) return;
    const barcode = userInfoState.member_app_card_no;
    const barcodeString =
      barcode.substring(0, 4) +
      '  ' +
      barcode.substring(4, 8) +
      '  ' +
      barcode.substring(8, 12) +
      '  ' +
      barcode.substring(12, 16);

    window.setTimeout(function () {
      JsBarcode('#barcode-img', barcode, {
        background: '#ffffff',
        text: barcodeString,
        height: 50,
        textAlign: 'center',
        textMargin: 15,
        fontSize: 15,
        font: `${thejamsilFont('light')}`,
      });
    }, 0);
  }, [userInfoState.member_app_card_no]);

  useEffect(() => {
    // 화면 밝기 최대
    bridgeBarcodeOpen();
  }, []);

  useEffect(() => {
    gaPageView(location.pathname, '', pageName);
  }, []);

  return (
    <>
      <Dim
        zIndex={200}
        onClick={onCloseAppCard}
        isDisplay={props.appCardPopupState === 'OPEN'}
      />
      <SlideUpPopupWrapper isOpen={props.appCardPopupState} overflow="N">
        <StyledAppCard>
          <button
            className="close_btn"
            onClick={onCloseAppCard}
            aria-label="닫기"
          >
            <IcoAppCardClose />
          </button>
          <div className="card_header" onClick={onCloseAppCard} aria-hidden>
            <div />
          </div>
          <div className="card_box">
            <div className="point">
              <h3>L.Point</h3>
              <p>
                <span>
                  {queries[0].isSuccess ? (
                    <>{lPoint ? commafy(lPoint.membs_use_psbt_point) : 0}p</>
                  ) : (
                    '-'
                  )}
                </span>
              </p>
            </div>
            <div className="barcode">
              <svg id="barcode-img"></svg>
            </div>
          </div>
          {!SUPER_CHECK(mainStoreState.str_cd) && (
            <div className="card_list">
              <ul>
                <li>
                  <div className="blue">SNOWPLAN</div>
                  <div className="right">
                    <span className="snow_point">
                      {queries[1].isSuccess ? (
                        <>
                          <strong>
                            {snowPointBalance
                              ? commafy(Number(snowPointBalance))
                              : 0}
                          </strong>{' '}
                          p
                        </>
                      ) : (
                        <strong>-</strong>
                      )}
                    </span>
                    <Button
                      text="사용하기"
                      variant="outlined"
                      buttonColor="blue"
                      onClick={handleclickSnowPointUse}
                    />
                  </div>
                </li>
                <li>
                  <div>L.PAY</div>
                  <div className="right">
                    <Button
                      text="결제하기"
                      variant="outlined"
                      buttonColor="blue"
                      onClick={handleClickLpay}
                    />
                  </div>
                </li>
                {mainStoreState.scango_yn == 'Y' && (
                  <li>
                    <div>SCAN & GO</div>
                    <div className="right">
                      <Button
                        text="결제하기"
                        variant="outlined"
                        buttonColor="black"
                        onClick={handleClickSmartPayment}
                      />
                    </div>
                  </li>
                )}
              </ul>
            </div>
          )}
        </StyledAppCard>
      </SlideUpPopupWrapper>
    </>
  );
}

export default AppCard;
