import React from 'react';
import { StyledSelectResult } from '../styles';
import BranchCard from './BranchCard';
import { TBranch, TMainStore } from 'src/types/types';

type TProps = {
  branchList: TBranch[];
  mainStore: TMainStore;
  scrollArea: React.RefObject<HTMLDivElement>;
  onChangeBranch: (onConfirm, branch) => void;
};

function SelectResult(props: TProps): React.ReactElement {
  return (
    <StyledSelectResult>
      <h3 className="sub_title">총 {props.branchList.length}건</h3>
      <div className="location_con" ref={props.scrollArea}>
        <ul className="mart_list">
          {props.branchList.map((branch, i) => (
            <BranchCard
              branch={branch}
              key={i}
              mainStore={props.mainStore}
              onChangeBranch={props.onChangeBranch}
            />
          ))}
        </ul>
      </div>
    </StyledSelectResult>
  );
}

export default SelectResult;
