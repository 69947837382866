import React, { useEffect } from 'react';

import { StyledSideMenu } from 'src/containers/App/components/SideMenu/styles';
import { E_MAIN_MENU_STATE } from 'src/redux/types/mainTypes';

import { SideMenuInfo } from './components/SideMenuInfo';
import Dim from 'src/components/Dim';
import SideMenuHeader from './components/SideMenuHeader';
import SideMenuPointSection from './components/SideMenuPointSection';
import SideMenuEventSection from './components/SideMenuEventSection';
import SideMenuConvenienceSection from './components/SideMenuConvenienceSection';
import SideMenuContentsSection from './components/SideMenuContentsSection';
import SideMenuBrandSection from './components/SideMenuBrandSection';
import SideMenuHelpSection from './components/SideMenuHelpSection';
import { useDispatch } from 'react-redux';
import { actionCloseMainMenu } from 'src/redux/actions/mainActions';
import { gaPageView } from 'src/utils/ga';

interface Props {
  isOpen: E_MAIN_MENU_STATE;
}

function SideMenu(props: Props): React.ReactElement {
  const { isOpen } = props;
  const dispatch = useDispatch();

  const handleClosePopup = () => {
    dispatch(actionCloseMainMenu());
  };

  useEffect(() => {
    gaPageView(location.pathname, '', `전체메뉴 팝업`);
  }, []);

  return (
    <>
      <Dim
        onClick={handleClosePopup}
        isDisplay={isOpen === E_MAIN_MENU_STATE.OPEN}
        zIndex={100}
      />
      <StyledSideMenu className={isOpen.toLowerCase()}>
        <div>
          <SideMenuHeader />
          <SideMenuInfo />
        </div>
        <div className="scroll">
          <SideMenuConvenienceSection />
          <SideMenuContentsSection />
          <SideMenuBrandSection />
          <SideMenuPointSection />
          <SideMenuEventSection />
          <SideMenuHelpSection />
        </div>
      </StyledSideMenu>
    </>
  );
}

export default SideMenu;
