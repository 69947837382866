import React, { HTMLAttributes } from 'react';
import { StyledDim } from './styles';

type TDimProps = HTMLAttributes<HTMLDivElement> & TDimStyleProps;

export type TDimStyleProps = {
  isDisplay?: boolean;
  zIndex: number;
};

function Dim({ ...props }: TDimProps): React.ReactElement {
  return <StyledDim {...props} />;
}

export default Dim;
