import { E_POPUP_STATE } from 'src/utils/enum';
import ActionTypes from '../constants/tenantConstants';
import { TTenantState, tenantAction } from '../types/tenantTypes';

export const initialState: TTenantState = {
  isOpenMartChangePopup: E_POPUP_STATE.NONE,
  isOpenTenantCouponPopup: E_POPUP_STATE.NONE,
  couponInfo: {
    event_id: '',
    cp_id: '',
    cp_title: '',
    cp_notice: '',
    cp_sort_no: '',
    cp_content_1: '',
    cp_content_2: '',
    cp_barcode: '',
    cp_start_dt: '',
    cp_end_dt: '',
    cp_status: '',
  },
  evtCpList: [
    {
      event_id: '',
      event_title: '',
      event_content: '',
      tenant_cd: '',
      tenant_grp_cd: '',
      event_start_dt: '',
      event_end_dt: '',
      remain_day: 0,
      event_img_path: '',
      event_img_desc: '',
      event_img_strge_nm: '',
      coupon_list: [],
    },
  ],
};

function TenantReducer(
  state: TTenantState = initialState,
  action: tenantAction,
): TTenantState {
  switch (action.type) {
    case ActionTypes.OPEN_MART_CHANGE_POPUP:
      return {
        ...state,
        isOpenMartChangePopup: E_POPUP_STATE.OPEN,
      };
    case ActionTypes.CLOSE_MART_CHANGE_POPUP:
      return {
        ...state,
        isOpenMartChangePopup: E_POPUP_STATE.CLOSE,
      };
    case ActionTypes.OPEN_TENANT_COUPON_POPUP:
      return {
        ...state,
        isOpenTenantCouponPopup: E_POPUP_STATE.OPEN,
      };
    case ActionTypes.CLOSE_TENANT_COUPON_POPUP:
      return {
        ...state,
        isOpenTenantCouponPopup: E_POPUP_STATE.CLOSE,
      };
    case ActionTypes.SET_STORE_COUPON_POPUP_INFO:
      return {
        ...state,
        couponInfo: action.payload.couponInfo,
      };
    case ActionTypes.SET_STORE_EVENT_COUPON_LIST:
      return {
        ...state,
        evtCpList: action.payload.evtCpList,
      };
    default:
      return state;
  }
}

export default TenantReducer;
