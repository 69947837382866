import { IMAGE_ROOT } from 'src/utils/constants';
import { css } from 'styled-components';

const FONT_PATH = `${IMAGE_ROOT}/fonts`;

// 폰트 정의
export const font = css`
  @font-face {
    font-family: 'TheJamsil-1Thin';
    src: url('${FONT_PATH}/TheJamsil-1-Thin.woff2') format('woff2'),
      url('${FONT_PATH}/TheJamsil-1-Thin.woff') format('woff');
  }
  @font-face {
    font-family: 'TheJamsil-2Light';
    src: url('${FONT_PATH}/TheJamsil-2-Light.woff2') format('woff2'),
      url('${FONT_PATH}/TheJamsil-2-Light.woff') format('woff');
  }
  @font-face {
    font-family: 'TheJamsil-3Regular';
    src: url('${FONT_PATH}/TheJamsil-3-Regular.woff2') format('woff2'),
      url('${FONT_PATH}/TheJamsil-3-Regular.woff') format('woff');
  }
  @font-face {
    font-family: 'TheJamsil-4Medium';
    src: url('${FONT_PATH}/TheJamsil-4-Medium.woff2') format('woff2'),
      url('${FONT_PATH}/TheJamsil-4-Medium.woff') format('woff');
  }
  @font-face {
    font-family: 'TheJamsil-5Bold';
    src: url('${FONT_PATH}/TheJamsil-5-Bold.woff2') format('woff2'),
      url('${FONT_PATH}/TheJamsil-5-Bold.woff') format('woff');
  }
  @font-face {
    font-family: 'TheJamsil-6ExtraBold';
    src: url('${FONT_PATH}/TheJamsil-6-ExtraBold.woff2') format('woff2'),
      url('${FONT_PATH}/TheJamsil-6-ExtraBold.woff') format('woff');
  }
`;

// 더 잠실체
export const thejamsilFont = (weight: string) => {
  switch (weight) {
    case 'thin':
      return css`
        font-family: 'TheJamsil-1Thin';
      `;
    case 'light':
      return css`
        font-family: 'TheJamsil-2Light';
      `;
    case 'regular':
      return css`
        font-family: 'TheJamsil-3Regular';
      `;
    case 'medium':
      return css`
        font-family: 'TheJamsil-4Medium';
      `;
    case 'bold':
      return css`
        font-family: 'TheJamsil-5Bold';
      `;
    case 'extrabold':
      return css`
        font-family: 'TheJamsil-6ExtraBold';
      `;
    default:
      return css``;
  }
};
