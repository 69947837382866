import ActionTypes from '../constants/snowPointConstants';
import { TSnowPointState, snowPointAction } from '../types/snowPointTypes';

export const initialState: TSnowPointState = {
  setSnowPointBalance: '',
};

function SnowPointReducer(
  state: TSnowPointState = initialState,
  action: snowPointAction,
): TSnowPointState {
  switch (action.type) {
    case ActionTypes.GET_SNOWPOINT_BALANCE:
      return {
        ...state,
        setSnowPointBalance: action.payload.snowPointBalance,
      };

    default:
      return state;
  }
}

export default SnowPointReducer;
