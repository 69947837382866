import React, { useEffect, useState, useRef, useMemo } from 'react';
import { useMutation, useQueries } from '@tanstack/react-query';
import { StyledMartSearchPopup } from './styles';
import { useDispatch } from 'react-redux';

import { actionCloseMartSearchPopup } from 'src/redux/actions/mainActions';
import { PopupHeaderWithTitleAndClose } from 'src/components/Popup/components/PopupHeader';

import { E_POPUP_STATE } from 'src/utils/enum';
import {
  actionCloseAlertPopup,
  actionCloseConfirmPopup,
  actionOpenAlertPopup,
  actionOpenConfirmPopup,
} from 'src/redux/actions/appActions';
import SelectBranch from './components/SelectBranch';
import SelectRegion from './components/SelectRegion';
import SelectResult from './components/SelectResult';
import Dim from 'src/components/Dim';
import {
  PopupMessageRemoveBag,
  PopupMessageSuper,
} from 'src/components/Popup/components/PopupMessages';
import { SlideUpFullScreenPopupWrapper } from 'src/components/Popup/components/SlideUpPopup';
import { TBranch, TBranchTerms, TRegion } from 'src/types/types';
import SelectResultSuper from './components/SelectResultSuper';
import { actionSetMainStoreState } from 'src/redux/actions/userActions';
import useSelectorEqual from 'src/hooks/useSelectorEqual';
import { RootState } from 'src/store/reducers';
import { getFilterBranchList } from 'src/utils/branch';
import {
  apiGetBranchList,
  apiGetMainStore,
  apiGetRegion,
  apiSetChangeMainStore,
} from 'src/api/apiUser';
import { apiRemoveBasket } from 'src/api/apiScanGo';
import { SUPER_CHECK, SUPER_INIT_DATA } from 'src/utils/constants';
import { gaEvent, gaPageView } from 'src/utils/ga';
import { actionSetMybenefitCouponList } from 'src/redux/actions/myBenefitAction';
import LoadingPopup from 'src/components/Popup/LoadingPopup';

interface TProps {
  isOpen: E_POPUP_STATE;
}

function MartSearchPopup(props: TProps): React.ReactElement {
  const [mainStoreState] = useSelectorEqual((state: RootState) => [
    state.user.mainStoreState,
  ]);

  const [searchBranch, setSearchBranch] = useState<TBranch[]>(); // 점포목록
  // 선택한 조건값
  const [selectTerms, setSelectTerms] = useState<TBranchTerms>({
    str_nm: '',
    region_cd: '',
    main_str_type: '',
  });

  const dispatch = useDispatch();
  const scorllArea = useRef<HTMLDivElement>(null);
  const pageName = '주이용점포 팝업';

  // 지역 및 점포목록 조회
  const queries = useQueries({
    queries: [
      { queryKey: ['regionList'], queryFn: apiGetRegion },
      {
        queryKey: ['branchList'],
        queryFn: () =>
          apiGetBranchList({ str_nm: '', main_str_type: '', region_cd: '' }),
        onSuccess: data => {
          setSearchBranch(data);
        },
        onError: () => {
          setSearchBranch([]);
        },
      },
    ],
  });

  // 주이용점포 조회
  const getMainStore = useMutation(apiGetMainStore, {
    onSuccess: data => {
      dispatch(actionSetMainStoreState(data));
    },
  });

  // 주이용점포 변경
  const setMainStore = useMutation(apiSetChangeMainStore, {
    onSuccess: () => {
      getMainStore.mutate();
    },
    onError: () => {
      alert(
        '주이용점포 변경 중 에러가 발생하였습니다.\n잠시후 다시 시도해주세요.',
      );
    },
  });

  // 장바구니 삭제
  const setRemoveBasket = useMutation(apiRemoveBasket);

  const regionList: TRegion[] = useMemo(() => {
    return queries[0].data;
  }, [queries]);
  const branchList: TBranch[] = useMemo(() => {
    return queries[1].data;
  }, [queries]);

  // 주이용점포 설정 여부
  const mainStoreYn =
    (branchList &&
      branchList.filter(branch => branch.main_yn === 'Y').length > 0) ||
    SUPER_CHECK(mainStoreState.str_cd)
      ? true
      : false;

  // 매장 선택 시
  const handleClickMartType = mart => {
    gaEvent('매장선택', '', `${mart.name}`, pageName);
    if (mart.id === '4') {
      // 주이용점포가 슈퍼일 경우
      if (mart.id === selectTerms.main_str_type) return;
      // 슈퍼 선택 시
      handleChangeBranch(handleOpenSuperNoticePopup, 'lstp');
      dispatch(actionSetMybenefitCouponList([])); //점포변경 시 쿠폰목록 초기화
    } else if (mart.id === selectTerms.main_str_type) {
      // 동일 매장 선택 시 매장 선택 초기화
      setSelectTerms({
        ...selectTerms,
        main_str_type: '',
      });
      getSearchBranch('', selectTerms.region_cd, selectTerms.str_nm);
    } else {
      // 선택한 매장 조회
      setSelectTerms({
        ...selectTerms,
        main_str_type: mart.id,
      });
      getSearchBranch(mart.id, selectTerms.region_cd, selectTerms.str_nm);
    }
  };

  // 지역 선택 시
  const handleChangeRegion = regionCd => {
    setSelectTerms({
      ...selectTerms,
      region_cd: regionCd,
    });
  };

  // 검색어 입력
  const handleChangeBranchName = e => {
    setSelectTerms({
      ...selectTerms,
      str_nm: e.target.value,
    });
  };

  // 닫기 버튼
  const handleClickClose = () => {
    // 주이용점포 미설정 시 '강변점'으로 설정
    if (!mainStoreYn) {
      setMainStore.mutate('301');
    }
    // 팝업 닫기
    dispatch(actionCloseMartSearchPopup());
  };

  // 검색 결과 필터링
  const getSearchBranch = (storeType, regionCd, strNm) => {
    if (!branchList) return;

    const _selectTerms: TBranchTerms = {
      main_str_type: storeType,
      region_cd: regionCd,
      str_nm: strNm,
    };

    const _filteredBranchList = getFilterBranchList(_selectTerms, branchList);

    setSearchBranch(_filteredBranchList);

    // 검색 시 목록 상위 이동
    scorllArea.current?.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
  };

  // 점포 변경
  const handleChangeBranch = (onConfirm, branch) => {
    if (branch.scango_yn === 'Y') {
      dispatch(
        actionOpenConfirmPopup({
          onConfirm: () => {
            setRemoveBasket.mutate(
              {
                str_cd: branch.str_cd,
                del_fg: 'Y',
              },
              {
                onSuccess: () => onConfirm(),
              },
            );
          },
          onClose: () => dispatch(actionCloseConfirmPopup()),
          confirmMessage: '네',
          cancelMessage: '아니요',
          children: <PopupMessageRemoveBag />,
        }),
      );
    } else {
      onConfirm();
    }
  };

  // 슈퍼 안내 팝업 열기
  const handleOpenSuperNoticePopup = () => {
    dispatch(actionCloseConfirmPopup());
    dispatch(
      actionOpenAlertPopup({
        onClose: () => {
          // 주이용점포 변경
          setMainStore.mutate(SUPER_INIT_DATA.str_cd);
          // 확인 팝업 닫기 및 주이용점포 팝업 닫기
          dispatch(actionCloseAlertPopup());
          dispatch(actionCloseMartSearchPopup());
        },
        children: <PopupMessageSuper />,
      }),
    );
  };

  // 주이용점포 SUPER일 경우 셋팅
  useEffect(() => {
    if (SUPER_CHECK(mainStoreState.str_cd)) {
      setSelectTerms({
        str_nm: 'SUPER',
        region_cd: '',
        main_str_type: '4',
      });
    }
  }, [mainStoreState]);

  useEffect(() => {
    sessionStorage.setItem('martSearchPoupupOpen', 'N');
  }, []);

  useEffect(() => {
    if (!(props.isOpen === 'OPEN' && searchBranch)) return;
    gaPageView(location.pathname, ``, `주이용점포 팝업`);
  }, [props.isOpen, searchBranch]);

  return (
    <>
      <Dim isDisplay={props.isOpen === 'OPEN'} zIndex={209} />
      <>
        {searchBranch ? (
          <SlideUpFullScreenPopupWrapper isOpen={props.isOpen} zIndex={210}>
            <PopupHeaderWithTitleAndClose
              onClose={handleClickClose}
              title={<>주 이용 점포 검색 </>}
            />
            <StyledMartSearchPopup>
              <div className="select_body">
                {/* 매장선택 */}
                <SelectBranch
                  selectStoreType={selectTerms.main_str_type}
                  onClickMartType={handleClickMartType}
                />
                {/* 지역 검색 */}
                {regionList && (
                  <SelectRegion
                    regionList={regionList}
                    selectTerms={selectTerms}
                    onChangeRegion={handleChangeRegion}
                    onChangeBranchName={handleChangeBranchName}
                    onClickSearch={getSearchBranch}
                  />
                )}
              </div>
              {/* 결과목록란 */}
              {selectTerms.main_str_type === '4' ? (
                <SelectResultSuper />
              ) : (
                <SelectResult
                  branchList={searchBranch}
                  mainStore={mainStoreState}
                  scrollArea={scorllArea}
                  onChangeBranch={handleChangeBranch}
                />
              )}
            </StyledMartSearchPopup>
          </SlideUpFullScreenPopupWrapper>
        ) : (
          <LoadingPopup />
        )}
      </>
    </>
  );
}

export default MartSearchPopup;
