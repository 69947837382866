import React from 'react';
import { IcoClose } from 'src/components/Icon';
import { StyledPopupHeader } from '../styles';

interface TPopupHeaderWithTitleAndCloseProps {
  onClose: () => void;
  title: React.ReactNode;
}

/**
 * @description 타이틀 + 닫기 아이콘
 */
export function PopupHeaderWithTitleAndClose(
  props: TPopupHeaderWithTitleAndCloseProps,
): React.ReactElement {
  return (
    <StyledPopupHeader>
      <h3>{props.title}</h3>
      <div
        className={'ico_close_wrapper'}
        onClick={props.onClose}
        role="button"
        aria-label="닫기"
      >
        <IcoClose />
      </div>
    </StyledPopupHeader>
  );
}

type TPopupHeaderOnlyCloseProps = {
  onClose: () => void;
};

/**
 * @description  닫기 아이콘
 */
export function PopupHeaderOnlyClose(
  props: TPopupHeaderOnlyCloseProps,
): React.ReactElement {
  return (
    <StyledPopupHeader>
      <div
        className={'ico_close_wrapper'}
        onClick={props.onClose}
        role="button"
        aria-label="닫기"
      >
        <IcoClose />
      </div>
    </StyledPopupHeader>
  );
}
